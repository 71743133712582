import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useRecoilState } from "recoil";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import Loader from "./components/global/Loader";
import SnackBar from "./components/global/SnackBar";
import AdminLayout from "./components/layout/AdminLayout";
import "./custom.scss";
import Companies from "./pages/Companies";
import CompanyUsers from "./pages/CompanyUsers";
import CreateJSA from "./pages/CreateJSA";
import CreateService from "./pages/CreateService";
import Dashboard from "./pages/Dashboard";
import DetailJsa from "./pages/DetailJsa";
import ErrorPage from "./pages/ErrorPage";
import JSA from "./pages/JSA";
import Leases from "./pages/Leases";
import Login from "./pages/Login";
import PoliciesAndProcedures from "./pages/PoliciesAndProcedures";
import ServiceDetail from "./pages/ServiceDetail";
import Services from "./pages/Services";
import ServicesRecords from "./pages/ServicesRecords";
import Test from "./pages/Test";
import UpdateJSA from "./pages/UpdateJSA";
import UpdateService from "./pages/UpdateService";
import Users from "./pages/Users";
import UsersDetail from "./pages/UsersDetail";
import Wells from "./pages/Wells";
import { isLoaderState, snakeBarState } from "./util/RecoilStore";

function App() {

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

  return (
    <>
      {isLoaderInfo && <Loader />}

      <div className="App">
        <Router>
          <Routes>
            <Route exact path={``} element={<Login />} />
            <Route exact path={`test`} element={<Test />} />
            <Route path={`/admin`} element={<AdminLayout />}>
              <Route exact path="dashboard" element={<Dashboard />} />
              <Route exact path="leases" element={<Leases />} />
              <Route exact path="companies" element={<Companies />} />
              <Route exact path="users" element={<Users />} />
              <Route exact path="wells" element={<Wells />} />
              <Route exact path="jsa" element={<JSA />} />
              <Route exact path="update-jsa/:id" element={<UpdateJSA />} />
              <Route exact path="jsa-detail/:id" element={<DetailJsa />} />
              <Route exact path="services" element={<Services />} />
              <Route exact path="policies-and-procedures" element={<PoliciesAndProcedures />} />
              <Route exact path="create-service/:id" element={<CreateService />} />
              <Route exact path="update-service" element={<UpdateService />} />
              <Route exact path="create-jsa" element={<CreateJSA />} />
              <Route exact path="service-records/:id" element={<ServicesRecords />} />
              <Route exact path="user-detail/:id" element={<UsersDetail />} />
              <Route exact path="company-users/:id" element={<CompanyUsers />} />
              <Route exact path="service-detail/:id" element={<ServiceDetail />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </div>
      <SnackBar
        closeSnackPro={() => setSnackBarInfo({ snackStatus: false, snackColor: '', snackMsg: '' })}
        snackInfoPro={snackBarInfo}
      />
    </>
  );
}

export default App;
