import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { CiEdit } from "react-icons/ci";
import { FaEye } from "react-icons/fa";
import { IoAddCircleSharp } from "react-icons/io5";
import { MdOutlineDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import CreateWells from "../components/forms/CreateWells";
import DeleteItemModal from "../components/forms/DeleteItemModal";
import UpdateWells from "../components/forms/UpdateWells";
import CompanySelect from "../components/global/CompanySelect";
import LeaseSelect from "../components/global/LeaseSelect";
import TableComponent from "../components/global/TableComponent";
import { apiPost } from "../util/ApiRequest";
import { BASE_IMG_URL, ENDPOINTS } from "../util/EndPoint";
import { isLoaderState, snakeBarState } from "../util/RecoilStore";

const Wells = () => {
  const navigate = useNavigate();

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

  const [userType, setUserType] = useState("");
  useEffect(() => {
    const storedUserType = localStorage.getItem("userType");
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, [userType]);

  const [reloadData, setReloadData] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const serviceOptions = [
    { value: "com-1", label: "Newest Well Added (ASC)" },
    { value: "com-2", label: "Newest Well Added (DESC)" },
    { value: "com-3", label: "Newest Service Record Added (ASC)" },
    { value: "com-3", label: "Newest Service Record Added (DESC)" },
  ];

  const columns = [
    {
      name: "Well number",
      selector: (row) => row?.name,
      key: "name",
      sortable: true,
    },

    {
      name: "Lease name",
      selector: (row) => row?.lease?.name,
      key: "name",
      sortable: true,
    },

    {
      name: "Company name",
      selector: (row) => row?.lease?.company?.name,
      key: "name",
      sortable: true,
    },
    {
      name: "SR count",
      selector: (row) =>
        row?.services_count ? row?.services_count?.services_count : 0,
      key: "name",
      sortable: false,
    },
    {
      name: "View service records",
      selector: (row) => (
        <button
          onClick={() => {
            navigate(`/admin/service-records/${row?.id}`);
          }}
          className="btn  icon-btn btn-primary"
        >
          {" "}
          <FaEye />{" "}
        </button>
      ),
      key: "name",
      sortable: false,
    },
  ];

  if (["0", "1"].includes(userType)) {
    columns.push({
      name: "New service records",
      selector: (row) => (
        <button
          onClick={() => {
            navigate(`/admin/create-service/${row?.id}`);
          }}
          className="btn  icon-btn btn-primary"
        >
          <IoAddCircleSharp />{" "}
        </button>
      ),
      key: "name",
      sortable: false,
    });
    columns.push({
      name: "Action",
      selector: (row) => (
        <div className="d-flex gap-2">
          <button
            onClick={() => {
              setSelectedItem(row);
              setEditModal(true);
            }}
            className="btn   btn-primary"
          >
            <CiEdit />{" "}
          </button>
          <button
            onClick={() => {
              setSelectedItem(row);
              setDeleteModal(true);
            }}
            className="btn  btn-danger"
          >
            <MdOutlineDelete />
          </button>
        </div>
      ),
      sortable: false,
    });
  }

  const [search, setSearch] = useState({
    name: "",
    companyId: "",
    leaseId: "",
    startDate: "",
    endDate: "",
  });

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const stopTime = document.getElementsByName("endDate")[0].value;
    const startTime = document.getElementsByName("startDate")[0].value;
    const body = {
      startDate: startTime,
      endDate: stopTime,
    };
    if (stopTime < startTime) {
      setSnackBarInfo({
        snackStatus: true,
        snackColor: "bg-danger",
        snackMsg: "Stop time must be greater than start time",
      });
    } else {
      setIsLoaderInfo(true);
      apiPost(
        ENDPOINTS.ServiceDownload,
        body,
        (res) => {
          const url = BASE_IMG_URL + res?.data;
          if (url) {
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
          // console.log(res.data);
          setSnackBarInfo({
            snackStatus: true,
            snackColor: "bg-success",
            snackMsg: "successfully Updated JSA",
          });
          setIsLoaderInfo(false);
        },
        (error) => {
          setSnackBarInfo({
            snackStatus: true,
            snackColor: "bg-danger",
            snackMsg: error?.data?.message[0]
              ? error?.data?.message[0]
              : "there is an error",
          });
          setIsLoaderInfo(false);
        }
      );
    }
  };
  return (
    <>
      <div className=" d-flex gap-3 mt-3">
        <div className="max-300 mb-3">
          <h6>Well number</h6>
          <Form.Control
            type="text"
            placeholder="well number"
            value={search.name}
            onChange={(e) =>
              setSearch((prevState) => ({
                ...prevState,
                name: e.target.value,
              }))
            }
          />

          <h6 className="mt-3">Company</h6>
          <CompanySelect
            setCompany={(e) =>
              setSearch((prevState) => ({
                ...prevState,
                companyId: e,
              }))
            }
          />

          <h6 className="mt-3">Lease</h6>
          <LeaseSelect
            setValue={(id) =>
              setSearch((prevState) => ({
                ...prevState,
                leaseId: id,
              }))
            }
            name={"leaseId"}
          />
        </div>

        {/* 
        <div className='itemsss'>
          <form onSubmit={handleSubmit}>
            <Form.Group className=" max-300 mb-3">
              <h6>Stat date</h6>
              <Form.Control

                onChange={(e) => setSearch(prevState => ({
                  ...prevState,
                  startDate: e.target.value,
                }))}
                value={search.startDate}

                name='startDate'
                type="date" placeholder="Stat date" required />

              <h6 className='mt-3'>End date</h6>
              <Form.Control type="date"
                onChange={(e) => setSearch(prevState => ({
                  ...prevState,
                  endDate: e.target.value,
                }))}
                value={search.endDate}

                name='endDate'

                required placeholder="End date" />

              <button className='btn btn-primary mt-3 '>Download</button>

            </Form.Group>
          </form>
        </div> */}
      </div>

      <div className="text-end mb-3">
        {["0", "1"].includes(userType) && (
          <button onClick={() => setAddModal(true)} className="btn btn-primary">
            Add Well
          </button>
        )}
      </div>

      <TableComponent
        title="Wells"
        endPoint={ENDPOINTS.WellPaginated}
        headers={columns}
        reloadData={reloadData}
        searchObject={search}
      />

      <CreateWells
        reloadData={() => {
          setAddModal(false);
          setReloadData(!reloadData);
        }}
        show={addModal}
        hideModal={() => setAddModal(false)}
      />

      <UpdateWells
        show={editModal}
        hideModal={() => setEditModal(false)}
        reloadData={() => {
          setEditModal(false);
          setReloadData(!reloadData);
        }}
        selectedItem={selectedItem}
      />

      <DeleteItemModal
        url={ENDPOINTS.WellDelete}
        body={{
          wellId: selectedItem.id,
        }}
        reloadData={() => {
          setDeleteModal(false);
          setReloadData(!reloadData);
        }}
        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to delete ${selectedItem.name} well?`}
        title="Delete Well"
      />
    </>
  );
};

export default Wells;
