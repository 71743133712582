import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import { IoAddCircleSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import CreateLease from '../components/forms/CreateLease';
import DeleteItemModal from '../components/forms/DeleteItemModal';
import UpdateLease from '../components/forms/UpdateLease';
import TableComponent from '../components/global/TableComponent';
import { apiPost } from '../util/ApiRequest';
import { BASE_IMG_URL, ENDPOINTS } from '../util/EndPoint';
import { isLoaderState, snakeBarState } from '../util/RecoilStore';


const Services = () => {
  const navigate = useNavigate();
  const [userType, setUserType] = useState('');
  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, [userType]);

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);


  const [reloadData, setReloadData] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});


  const serviceOptions = [
    { value: 'com-1', label: 'Newest Well Added (ASC)' },
    { value: 'com-2', label: 'Newest Well Added (DESC)' },
    { value: 'com-3', label: 'Newest Service Record Added (ASC)' },
    { value: 'com-3', label: 'Newest Service Record Added (DESC)' }
  ]




  const columns = [

    {
      name: 'Well',
      selector: (row) => row?.well?.name,
      sortable: false,
    },
    {
      name: 'Lease',
      selector: (row) => row?.well?.lease?.name,
      sortable: false,
    },
    {
      name: 'company',
      selector: (row) => row?.well?.lease?.company?.name,
      sortable: false,
    },
    {
      name: 'Inspection date',
      selector: (row) => <span>{moment(row?.date).utc().format('YYYY-MM-DD')}</span>,
      key: 'name',
      sortable: true,
    },
    {
      name: 'Actions',
      selector: (row) => <div className='d-flex gap-2'>
        <button onClick={() => { navigate(`/admin/service-detail/${row.id}`) }} className='btn  icon-btn btn-primary'><FaEye /> </button>
      </div>,
      key: 'name',
      sortable: true,
    },



  ];


  if (['0','1'].includes(userType)) {
    columns.push({
      name: 'New service records',
      selector: (row) => <button onClick={() => { navigate(`/admin/create-service/${row?.wellId}`) }} className='btn  icon-btn btn-primary'><IoAddCircleSharp /> </button>,
      key: 'name',
      sortable: false,
    });

  }



  const handleSubmit = (event) => {

    event.preventDefault();
    const stopTime = document.getElementsByName('endDate')[0].value;
    const startTime = document.getElementsByName('startDate')[0].value;
    const body = {
      startDate: startTime,
      endDate: stopTime,
    }
    if (stopTime < startTime) {
      setSnackBarInfo({
        snackStatus: true,
        snackColor: "bg-danger",
        snackMsg: "Stop time must be greater than start time",
      });
    } else {


      setIsLoaderInfo(true)
      apiPost(
        ENDPOINTS.ServiceDownload,
        body,
        (res) => {
          const url =BASE_IMG_URL+res?.data;
          if (url) {
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
          // console.log(res.data);
          setSnackBarInfo({
            snackStatus: true,
            snackColor: "bg-success",
            snackMsg: "successfully Updated JSA",
          });
          setIsLoaderInfo(false)

        },
        (error) => {
          setSnackBarInfo({
            snackStatus: true,
            snackColor: "bg-danger",
            snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
          });
          setIsLoaderInfo(false)
        }
      );
    }
  }


  const [search, setSearch] = useState({
    endDate:'',
    startDate:'',
  });



  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);


  return (
    <>

<div className='itemsss'>
          <form onSubmit={handleSubmit}>
            <Form.Group className=" max-300 mb-3">
              <h6>Stat date</h6>
              <Form.Control

                onChange={(e) => setSearch(prevState => ({
                  ...prevState,
                  startDate: e.target.value,
                }))}
                value={search.startDate}

                name='startDate'
                type="date" placeholder="Stat date" required />

              <h6 className='mt-3'>End date</h6>
              <Form.Control type="date"
                onChange={(e) => setSearch(prevState => ({
                  ...prevState,
                  endDate: e.target.value,
                }))}
                value={search.endDate}

                name='endDate'

                required placeholder="End date" />

              <button className='btn btn-primary mt-3 '>Download</button>

            </Form.Group>
          </form>
        </div>


      <TableComponent
        title="Services"
        endPoint={ENDPOINTS.ServicePaginated}
        headers={columns}
        reloadData={reloadData}
        searchObject={search}
      />

      <CreateLease
        show={addModal}
        hideModal={() => setAddModal(false)}
        reloadData={() => {
          setAddModal(false)
          setReloadData(!reloadData)
        }}

      />
      <UpdateLease
        show={editModal}
        hideModal={() => setEditModal(false)}
        reloadData={() => {
          setEditModal(false)
          setReloadData(!reloadData)
        }}
        selectedItem={selectedItem} />

      <DeleteItemModal
        url={ENDPOINTS.LeaseDelete}
        body={{
          leaseId: selectedItem.id
        }}
        reloadData={() => {
          setDeleteModal(false)
          setReloadData(!reloadData)
        }}


        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to delete ${selectedItem.name} lease?`}
        title="Delete Lease" />

    </>
  )
}

export default Services