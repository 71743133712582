import React, { useEffect, useState } from 'react';
import { CiEdit } from "react-icons/ci";
import { MdOutlineDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import CreateCompany from '../components/forms/CreateCompany';
import DeleteItemModal from '../components/forms/DeleteItemModal';
import UpdateCompany from '../components/forms/UpdateCompany';
import TableComponent from '../components/global/TableComponent';
import { ENDPOINTS } from '../util/EndPoint';
import { isLoaderState, snakeBarState } from '../util/RecoilStore';


const Companies = () => {
  const navigate = useNavigate();



  const [userType, setUserType] = useState('');
  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, []);

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);


  const [reloadData, setReloadData] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);



  const [items, setItems] = useState([]);
  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});



  const [search, setSearch] = useState({
    name: '',
    contact: '',
    address: '',
    uri: '',
    orderByColumn: '',
    orderBy: true,
  });

  const columns = [

    {
      name: 'Name',
      selector: (row) => row.name,
      key: 'name',
      sortable: true,
    },
    {
      name: 'Contact',
      selector: (row) => row.contact,
      key: 'contact',
      sortable: true,
    },

    {
      name: 'Address',
      selector: (row) => row.address,
      key: 'address',
      sortable: true,
    },

    {
      name: 'Uri',
      selector: (row) => row.uri,
      key: 'uri',
      sortable: true,
    },

    {
      name: 'Users',
      selector: (row) => <button onClick={() => navigate(`/admin/company-users/${row?.id}`)} className='btn btn-primary'>detail</button>,
      key: 'uri',
      sortable: false,
    },

    {
      name: 'Leases',
      selector: (row) => <span>{row?.leases?.length}</span>,
      key: 'uri',
      sortable: false,
    },
    {
      name: 'Wells',
      selector: (row) => <span>{row?.well_count}</span>,
      key: 'uri',
      sortable: false,
    },

    {
      name: 'Action',
      selector: (row) =>
        <div className='d-flex gap-2'>
          <button onClick={() => {
            setSelectedItem(row)
            setEditModal(true)
          }} className='btn  icon-btn btn-primary me-2'><CiEdit /> </button>

          <button onClick={() => {
            setSelectedItem(row)
            setDeleteModal(true)
          }} className='btn icon-btn btn-danger'><MdOutlineDelete /></button>
        </div>,
      sortable: false,
    },


  ];








  return (
    <>

{/* 
      <div className=' d-flex gap-3 mt-3'>
        <div className='max-300 mb-3'>

          <h6>Name</h6>
          <Form.Control type="text" placeholder='name' value={search.name}
            onChange={(e) => setSearch(prevState => ({
              ...prevState,
              name: e.target.value
            }))}
          />
          <h6 className='mt-3'>Contact</h6>
          <Form.Control type="text" placeholder='contact' value={search.contact}
            onChange={(e) => setSearch(prevState => ({
              ...prevState,
              contact: e.target.value
            }))}
          />

        </div>

        <div className='max-300 mb-3'>
          <h6>Address</h6>
          <Form.Control type="text" placeholder='address' value={search.address}
            onChange={(e) => setSearch(prevState => ({
              ...prevState,
              address: e.target.value
            }))}
          />


          <h6 className='mt-3'>Uri</h6>
          <Form.Control type="text" placeholder='uri' value={search.uri}
            onChange={(e) => setSearch(prevState => ({
              ...prevState,
              uri: e.target.value
            }))}
          />
        </div>

      </div> */}


      {userType == 0 && (
        <div className='text-end mb-3'>
          <button onClick={() => setAddModal(true)} className='btn btn-primary'>Add Company</button>
        </div>
      )}


      <div className='paginated-table-inner table-responsive'>

        <TableComponent
          title="Company"
          endPoint={ENDPOINTS.CompanyPaginated}
          headers={columns}
          reloadData={reloadData}
          searchObject={search}
        />


      </div>

      <CreateCompany

        reloadData={() => {
          setAddModal(false)
          setReloadData(!reloadData);
        }}
        show={addModal}
        hideModal={() => setAddModal(false)}
      />
      <UpdateCompany
        reloadData={() => {
          setEditModal(false)
          setReloadData(!reloadData);
        }}
        selectedItem={selectedItem}
        show={editModal}
        hideModal={() => setEditModal(false)} />


      <DeleteItemModal
        url={ENDPOINTS.CompanyDelete}
        body={{
          companyId: selectedItem.id
        }}
        reloadData={() => {
          setDeleteModal(false)
          setReloadData(!reloadData);
        }}


        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to delete ${selectedItem.name} Company?`}
        title="Delete Company" />
    </>
  )
}

export default Companies