import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js';
import React, { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import ReactPaginate from "react-paginate";
import { useRecoilState } from 'recoil';
import { apiGetAuth } from '../../util/ApiRequest';
import { ENDPOINTS } from '../../util/EndPoint';
import { isLoaderState } from '../../util/RecoilStore';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const RiskChart = () => {
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
 
    const [items, setItems] = useState([]);
    const [labels, setLabels] = useState([]);


    const [currentUrl, setCurrentUrl] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [maxBarCount, setMaxBarCount] = useState(0);



  
     const options = {
        plugins: {
            title: {
                display: true,
                text: 'Risk Chart',
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                max: maxBarCount+2,
                min:0   
         
            },
        },
    };


    useEffect(() => {
        const url = "";
        getData(url)
    }, []);

    const getData = (url) => {
        apiGetAuth(
            ENDPOINTS.ChartRisk +url,
            (res) => {
                setItems(res?.results);
                setLabels(res?.label)
                setTotalPage(res?.meta?.totalPages);
                setIsLoaderInfo(false);
                setMaxBarCount(res?.bar_max_count)

            },
            (error) => {
                console.log(error, "Users Year Bar")
            }
        );
    };

    

    const handlePageClick = (item) => {
        const url = `?page=${item.selected + 1}&limit=10`;
        setCurrentUrl(url)
        getData(url);
    };

    
    const data = {
        labels: labels.map(label => label.name),
                datasets: [
            {
                label: 'Wells',
                data: labels.map(label => {
                    const single = items.find(e => e.id === label.id);
                    return single.wells_count ?single?.wells_count?.wells_count:0; 
                }),
                backgroundColor: '#081993fc',
                stack: 'Stack 0',
                
            },
            {
                label: 'Risk Units',
                data: labels.map(label => {
                    const single = items.find(e => e.id === label.id);
                    return single?.count; 
                }),
                backgroundColor: 'rgb(75, 192, 192)',
                stack: 'Stack 1',
              },

        ],
    };

    return (
        <div>
        

            {items?.length > 0 && (
                <Bar options={options} data={data} />
            )}


<ReactPaginate
                autoWidth
                className="pagination-custom mt-4"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={20}
                pageCount={totalPage}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
            />
        </div>
    )
}

export default RiskChart