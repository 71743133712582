import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import CustomCheckBox from '../components/global/CustomCheckBox';
import CustomDateInput from '../components/global/CustomDateInput';
import CustomDateTimeInput from '../components/global/CustomDateTimeInput';
import CustomInput from '../components/global/CustomInput';
import Draw from '../components/global/Draw';
import SignatureComponent from '../components/global/SignatureComponent';
import { apiPost } from '../util/ApiRequest';
import { BASE_IMG_URL, ENDPOINTS } from '../util/EndPoint';
import { isLoaderState, snakeBarState } from '../util/RecoilStore';

const UpdateJSA = () => {
  const navigate = useNavigate();

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
  const [itemId, setItmeId] = useState(null);
  const [itemObj, setItemObj] = useState({});


  const [listOfSignsCount, setListOfSignsCount] = useState(0)
  const [signData, setSignData] = useState([])
  const [diagramData, setDiagramData] = useState([])

  const [selectedDeleteItems, setSelectedDeleteItems] = useState([])

  const [newDiagram, setNewDiagram] = useState(false)


  useEffect(() => {
    let ids = /[^/]*$/.exec(window.location.href)[0];
    setItmeId(ids);
    getItemInfo(ids);
  }, []);

  const getItemInfo = (ids) => {
    setIsLoaderInfo(true)
    let body = {
      jsa_id: ids,
    };
    apiPost(
      ENDPOINTS.JsaSingle,
      body,
      (res) => {
        setItemObj(res.data);
        setIsLoaderInfo(false)
      },
      (error) => {
        console.log(error, ' error');
      },
    );
  };


  const JsaDeleteMedia = (fileId, type) => {
    setIsLoaderInfo(true)
    let body = {
      jsa_id: itemObj?.id,
      file_id: fileId,
      type: type
    };
    apiPost(
      ENDPOINTS.JsaDeleteMedia,
      body,
      (res) => {
        getItemInfo(itemObj?.id)
        setIsLoaderInfo(false)
      },
      (error) => {
        console.log(error, ' error');
      },
    );
  };



  const handleSubmit = (event) => {

    
    event.preventDefault();

    const stopTime = document.getElementsByName('stopTime')[0].value;
    const startTime = document.getElementsByName('startTime')[0].value;
    event.preventDefault();

    if (stopTime < startTime) {
      setSnackBarInfo({
            snackStatus: true,
            snackColor: "bg-danger",
            snackMsg: "Stop time must be greater than start time",
        });
   }else{

    const body = new FormData(event.target);

    if (selectedDeleteItems?.length > 0) {
      selectedDeleteItems?.forEach((item) => body.append("delete_sign_ids[]", item))
    }

    if (signData?.length > 0) {
      body.append('sign', JSON.stringify(signData));
    }

    if (newDiagram) {
      if (diagramData?.length > 0) {
        body.append('diagram', JSON.stringify(diagramData));
      }
    }


    setIsLoaderInfo(true)
    apiPost(
      ENDPOINTS.JsaUpdate,
      body,
      (res) => {
        // console.log(res.data);
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "successfully Updated JSA",
        });
        navigate(`/admin/jsa`);
        setIsLoaderInfo(false)

      },
      (error) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
        });
        setIsLoaderInfo(false)
      }
    );
  }
  }

  const handleSelectedDelete = (file_id) => {
    let newData = [...selectedDeleteItems];
    let foundIndex = newData.findIndex(item => item == file_id);

    if (foundIndex >= 0) {
      newData = newData.filter(item => item != file_id);
      setSelectedDeleteItems(newData);
    } else {
      newData.push(file_id);
      setSelectedDeleteItems(newData);
    }

  }

  const signDataHandle = (data, index) => {
    const newData = [...signData];
    let found = newData.find(item => item.key == index);
    if (found) {
      found.data = data;
    }
    if (!found) {
      newData.push({ key: index, data: data });
    }
    setSignData(newData);
  }

  const diagramDataHandle = (data) => {
    const newData = [...diagramData];

    let found = newData.find(item => item.key == 1);

    if (found) {
      found.data = data;
    }
    if (!found) {
      newData.push({ key: 1, data: data });
    }
    setDiagramData(newData);
  }

  return (
    <div className='container-fluid p-4 custom-overly'>
      {itemId && (
        <form onSubmit={handleSubmit} className=''>

          <h3 className='text-center mb-4'>Update JSA</h3>

          <input type='hidden' value={itemId} name='jsa_id' />

          <div className='row border border-primary px-3 py-4 mb-4 rounded-3'>
          <div className='col-lg-6 col-md-12 mb-2'>
              <CustomInput
                label="Lead Person/Operator"
                placeholder="Lead Person/Operator"
                type="text"
                required={true}
                name="operator"
                defaultValue={itemObj?.operator}
              />
            </div>

   <div className='col-lg-6 col-md-12 mb-2'>
              <CustomInput
                label="Operator Name/Phone *"
                placeholder="Operator Name/Phone *"
                type="text"
                required={true}
                name="operatorPhone"
                defaultValue={itemObj?.operatorPhone}
              />
            </div>

          </div>

          <div className='row border border-primary px-3 py-4 mb-4 rounded-3'>
            <div className='col-lg-6 col-md-12 mb-2'>
              <CustomDateInput
                label="Date"
                placeholder="Date"
                type="date"
                required={true}
                name="date"
                defaultValue={itemObj?.date}
                setValue={(val) => console.log(val)}
              />

            </div>

          

            <div className='col-lg-6 col-md-12 mb-2'>
              <CustomInput
                label="Location"
                placeholder="Location"
                type="text"
                required={true}
                name="location"
                defaultValue={itemObj?.location}
              />
            </div>

            <div className='col-lg-6 col-md-12 mb-2'>
              <CustomInput
                label="Activity"
                placeholder="Activity"
                type="text"
                required={true}
                name="activity"
                defaultValue={itemObj?.activity}
              />
            </div>

            <div className='col-lg-6 col-md-12 mb-2'>
              <CustomDateTimeInput
                label="Start Time"
                placeholder="Start Time"
                required={true}
                name="startTime"
                defaultValue={itemObj?.startTime ? moment(itemObj.startTime).format('YYYY-MM-DDTHH:mm') : ''}
              />
            </div>

            <div className='col-lg-6 col-md-12 mb-2'>
              <CustomDateTimeInput
                label="Stop Time"
                placeholder="Stop Time"
                required={true}
                name="stopTime"
                defaultValue={itemObj?.stopTime ? moment(itemObj.stopTime).format('YYYY-MM-DDTHH:mm') : ''}
              />
            </div>

            <div className='col-lg-6 col-md-12 mb-2'>
              <CustomInput
                label="Taligate Topic *"
                placeholder="Taligate Topic *"
                type="text"
                required={true}
                name="taligateTopic"
                defaultValue={itemObj?.taligateTopic}
              />
            </div>

            <div className='col-lg-6 col-md-12 mb-2'>
              <CustomInput
                label="Customer W/O *"
                placeholder="Customer W/O *"
                type="text"
                required={true}
                name="customer"
                defaultValue={itemObj?.customer}
              />
            </div>

            <div className='col-lg-6 col-md-12 mb-2'>
              <CustomInput
                label="Emergency Response Site *"
                placeholder="Emergency Response Site *"
                type="text"
                required={true}
                name="emergencySite"
                defaultValue={itemObj?.emergencySite}
              />
            </div>

     
            <div className='col-12 mt-4'>
              <h4>Do the following known hazards exists?</h4>
            </div>



          </div>

          <div className="row border border-primary px-3 py-4 mb-4 rounded-3">
            <CustomCheckBox
              defaultValue={itemObj?.hazards?.pinchPoints == 1 ? true : false}
              name="hazards_pinchPoints"
              label="Line of Fire/Pinch Points"

            />
            <CustomCheckBox
              defaultValue={itemObj?.hazards?.hotSurfaces == 1 ? true : false}
              name="hazards_hotSurfaces"
              label="Hazardous Atmosphere/Hot Surfaces"

            />
            <CustomCheckBox
              defaultValue={itemObj?.hazards?.workingSurfaces == 1 ? true : false}
              name="hazards_workingSurfaces"
              label="Walking Working Surfaces"

            />
            <CustomCheckBox
              defaultValue={itemObj?.hazards?.powerLines == 1 ? true : false}
              name="hazards_powerLines"
              label="Power Lines"

            />
            <CustomCheckBox
              defaultValue={itemObj?.hazards?.equip == 1 ? true : false}
              name="hazards_equip"
              label="Vehicle / Hvy. Equip. Movement"

            />
            <CustomCheckBox
              defaultValue={itemObj?.hazards?.fallPotential == 1 ? true : false}
              name="hazards_fallPotential"
              label="Fall Potential"

            />
            <CustomCheckBox
              defaultValue={itemObj?.hazards?.hazardousEnergy == 1 ? true : false}
              name="hazards_hazardousEnergy"
              label="Hazardous Energy"

            />
            <CustomCheckBox
              defaultValue={itemObj?.hazards?.heavyObjects == 1 ? true : false}
              name="hazards_heavyObjects"
              label="Moving Heavy Objects"

            />
            <CustomCheckBox
              defaultValue={itemObj?.hazards?.pwrTools == 1 ? true : false}
              name="hazards_pwrTools"
              label="Rotating Machinery & Pwr. Tools"

            />
            <CustomCheckBox
              defaultValue={itemObj?.hazards?.other == 1 ? true : false}
              name="hazards_other"
              label="Other"

            />


          </div>

          <div className='row border border-primary px-3 py-4 mb-4 rounded-3'>
            <div className='col-12'>
              <h6>Hazard identification & Mitigation: Identify the hazard(s) by #. Clearly identify the hazard(s) & corrective action necessary to mitigate the hazard(s).</h6>
              <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  name='comments'
                  placeholder='Comments'
                  as="textarea"
                  rows={3}
                  value={itemObj?.comments || ''}
                />
              </Form.Group>
              <h6>The following checklist must be reviewed during a pre-job planning/tailgate meeting conducted at the work location. Any deficiencies shall be noted and this form will be kept on file in the office.</h6>
            </div>

          </div>

          <div className="row border border-primary px-3 py-4 mb-4 rounded-3">

            <CustomCheckBox
              defaultValue={itemObj?.deficiencies?.equipInUse == 1 ? true : false}
              name="deficiencies_equipInUse"
              label="Proper Protective Equipment In Use"
            />
            <CustomCheckBox
              defaultValue={itemObj?.deficiencies?.valvesIdentified == 1 ? true : false}
              name="deficiencies_valvesIdentified"
              label="Suction/discharge valves identified"
            />

            <CustomCheckBox
              defaultValue={itemObj?.deficiencies?.fireExtinguiser == 1 ? true : false}
              name="deficiencies_fireExtinguisher"
              label="Fire Extinguisher(s) Checked"
            />

            <CustomCheckBox
              defaultValue={itemObj?.deficiencies?.houseKeeping == 1 ? true : false}
              name="deficiencies_houseKeeping"
              label="Housekeeping —OK"
            />

            <CustomCheckBox
              defaultValue={itemObj?.deficiencies?.triMonitor == 1 ? true : false}
              name="deficiencies_triMonitor"
              label="H2S/TRI Monitor - OK"
            />
            <CustomCheckBox
              defaultValue={itemObj?.deficiencies?.barricades == 1 ? true : false}
              name="deficiencies_barricades"
              label=" Barricades and/or Cones in place"
            />

            <div className='col-12  mt-3'>
              <h6>In the box below, diagram the work site. Indicate the approx. location of any overhead power lines, steam or flow lines, vehicle(s) location(s), and any other potential or additional hazards</h6>
            </div>
          </div>

          {/* for diagram Start  */}
          <div className="row mt-3">
            {itemObj?.diagram != null && !newDiagram && (
              <div className='selected-sign-box  col-12 position-relative border border-danger' >
                <button onClick={() => {
                  setNewDiagram(true)
                  JsaDeleteMedia(itemObj?.diagram[0]?.file_id, 'diagram')
                }}
                  type='button'
                  className='selected-delete btn btn-danger'
                >
                  Delete
                </button>
                <Image thumbnail className='border-0 diagram-img-custom' src={BASE_IMG_URL + itemObj?.diagram[0]?.path} />
              </div>
            )}

            {newDiagram && (
              <div className='col-12 mt-3'>
                <Draw handleDiagramData={(data) => diagramDataHandle(data)} />
              </div>
            )}


          </div>


          {/* for diagram End  */}


          {/* for sign Start  */}

          <div className="row mt-3">
            {itemObj?.sign != null && (
              <div className='col-md-12 col-lg-12 mb-2 d-flex justify-content-center flex-wrap gap-2'>
                {JSON.parse(itemObj?.sign)?.length > 0 &&
                  JSON.parse(itemObj?.sign).map((item, index) => (
                    <div key={index} className='selected-sign-box position-relative' >
                      <button type='button'
                        onClick={() => JsaDeleteMedia(item?.file_id, 'sign')}
                        className='selected-delete btn btn-danger'>
                        delete 
                      </button>
                      <Image key={index} className='border border-primary sign-image' src={BASE_IMG_URL + item.path} />
                    </div>
                  ))}
              </div>
            )}


            <div className='col-12'>
              <div className='signature-com-box'>
                {listOfSignsCount > 0 && (
                  <>
                    {/* Loop 5 times to render signatures */}
                    {[...Array(listOfSignsCount)].map((_, index) => (
                      <div key={index} className='signature-com'>
                        <h2>Signature</h2>
                        <SignatureComponent
                          handleSignData={(data, index) => signDataHandle(data, index)}
                          index={index}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className='text-center mb-4'>
                <button type='button' className='btn btn-primary font-12'
                onClick={() => setListOfSignsCount((prevCount => prevCount + 1))}
                >Add Signature</button>
              </div>
            </div>
          </div>

          {/* for sign End  */}

          <div className="row mt-3">
            <div className='col-12 text-end'>
              <button className='btn btn-danger me-2'>cancel</button>
              <button className='btn btn-primary'>Update</button>
            </div>
          </div>
        </form>
      )}

    </div>
  )
}

export default UpdateJSA