import React from 'react';
import { Form } from 'react-bootstrap';

const UnitTypeSelect = ({ name, label, setValue, defaultValue }) => {
    return (
        <>
            <label className="mb-2">{label}</label>
            <Form.Select
                onChange={(e) => setValue(e.target.value)}
                name={name}
                defaultValue={defaultValue}>
                <option value="Conventional">Conventional</option>
                <option value="Beam">Beam</option>
                <option value="Balance">Balance</option>
                <option value="Air Balance3">Air Balance</option>
                <option value="Mark II">Mark II</option>
            </Form.Select>
        </>
    )
}

export default UnitTypeSelect