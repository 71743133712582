import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { MdDelete } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useRecoilState } from 'recoil';
import { apiPost } from '../../util/ApiRequest';
import { BASE_IMG_URL, ENDPOINTS } from '../../util/EndPoint';
import { isLoaderState, snakeBarState } from '../../util/RecoilStore';
import DeleteItemModal from '../forms/DeleteItemModal';
import CustomDropzone from './CustomDropzone';

const PoliciesAndProceduresTable = ({ type }) => {



    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);



    const [items, setItems] = useState([]);
    const [currentUrl, setCurrentUrl] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);

    const [selectItem, setSelectItem] = useState({});


    useEffect(() => {
        const url = "";
        setCurrentUrl(url);
        getPaginated(url);
    }, [type]);




    const handlePageClick = (item) => {
        const url = `?page=${item.selected + 1}&limit=10`;
        getPaginated(url);
    };


    const getPaginated = (url) => {
        setIsLoaderInfo(true);
        apiPost(
            ENDPOINTS.PoliciesAndProceduresPaginated + url,
            { type },
            (res) => {
                setItems(res?.data?.results);
                setTotalPage(res?.data?.meta?.totalPages);
                setIsLoaderInfo(false);
            },
            (error) => {
                console.log(error, 'error');
                setIsLoaderInfo(false);
            }
        );
    };


    const deleteFile = () => {
        if (selectItem?.id) {
            setIsLoaderInfo(true);
            apiPost(
                ENDPOINTS.PoliciesAndProceduresDelete,
                { id: selectItem.id },
                (res) => {
                    setDeleteModal(false)
                    getPaginated(currentUrl)
                },
                (error) => {
                    console.log(error, 'error');
                    setIsLoaderInfo(false);
                }
            );
        }
    };



    return (
        <div className='mt-5'>

            <div className='mt-3 mb-2'>
                <CustomDropzone type={type} reloadData={() => getPaginated(currentUrl)} fileName={'media'} />
            </div>

            <h4 className='mb-3'>Uploaded Documents List  </h4>
            <Table className='' striped hover>
                <tbody>
                    {items?.length > 0 &&
                        items.map((item, index) => (
                            <tr key={index}>
                                <td>{item?.originalName}</td>
                                <td>
                                    <div className='d-flex gap-2'>
                                        <a className='btn btn-primary' href={BASE_IMG_URL + item?.fileName} target="_blank" rel="noopener noreferrer">Preview</a>

                                        <button onClick={() => {
                                            setSelectItem(item)
                                            setDeleteModal(true)
                                        }} className='btn btn-danger'>  <MdDelete /></button>
                                    </div>
                                </td>

                            </tr>
                        ))}

                </tbody>
            </Table>

            {items?.length == 0 && (
                <h5 className='text-danger text-center'> record not found</h5>
            )}

            <ReactPaginate
                autoWidth
                className="pagination-custom"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={10}
                pageCount={totalPage}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
            />



            <DeleteItemModal
                url={ENDPOINTS.PoliciesAndProceduresDelete}
                body={{
                    id: selectItem.id
                }}
                reloadData={() => {
                    setDeleteModal(false)
                    getPaginated(currentUrl);
                }}


                show={deleteModal}
                hideModal={() => setDeleteModal(false)}
                text={`Do you want to delete ${selectItem?.originalName} ?...`}
                title="Delete Pdf"
            />

        </div>
    )
}

export default PoliciesAndProceduresTable