import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { apiGetAuth } from '../../util/ApiRequest';
import { ENDPOINTS } from '../../util/EndPoint';



const CompanySelect = ({ setCompany, name, defaultValue }) => {

    const [items, setItems] = useState([]);


    useEffect(() => {
        getRecord()
    }, []);


    const getRecord = () => {
        apiGetAuth(
            ENDPOINTS.CompanyDropdown,
            (res) => {
                setItems(res);

            },
            (error) => {
                console.log(error);

            }
        );
    };
    const defaultOption = items.find(option => option.value === defaultValue);

    return (
        <div>
            {items?.length > 0 && (
                <Select
                    className='select-custom'
                    name={name}
                    onChange={(item) => setCompany(item.value)}
                    options={items}
                    defaultValue={defaultOption}


                />
            )}
        </div>
    )
}

export default CompanySelect