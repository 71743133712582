import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PoliciesAndProceduresTable from '../components/global/PoliciesAndProceduresTable';

const PoliciesAndProcedures = () => {
    const [activeTab, setActiveTab] = useState("1")
    const handleTabChange = (selectedTab) => {
        setActiveTab(selectedTab);
    };


    return (

        <div className='custom-nav-tabs'>
            <Tabs
                defaultActiveKey="1"
                transition={false}
                className="mb-3"
                onSelect={handleTabChange}
            >
                <Tab eventKey="1" title="HSE Policies">
                    <h2 className='mt-4'>HSE Policies</h2>
                    <PoliciesAndProceduresTable type={activeTab} />
                </Tab>


                <Tab eventKey="2" title="SOPs">
                    <h2 className='mt-4'>SOPs</h2>
                    <PoliciesAndProceduresTable type={activeTab} />
                </Tab>

                <Tab eventKey="3" title="SDS">
                    <h2 className='mt-4'>Safety Data Sheets</h2>
                    <PoliciesAndProceduresTable type={activeTab} />
                </Tab>

            </Tabs>
        </div>
    );
};

export default PoliciesAndProcedures;
