import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { CiEdit } from "react-icons/ci";
import { MdOutlineDelete } from "react-icons/md";
import { useRecoilState } from 'recoil';
import CreateLease from '../components/forms/CreateLease';
import DeleteItemModal from '../components/forms/DeleteItemModal';
import UpdateLease from '../components/forms/UpdateLease';
import CompanySelect from '../components/global/CompanySelect';
import TableComponent from '../components/global/TableComponent';
import { ENDPOINTS } from '../util/EndPoint';
import { isLoaderState, snakeBarState } from '../util/RecoilStore';


const Leases = () => {


  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

  const [userType, setUserType] = useState('');
  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, [userType]);


  const [reloadData, setReloadData] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      key: 'name',
      sortable: true,
    },
    {
      name: 'Company',
      selector: (row) => row.company.name,
      sortable: false,
    },
    {
      name: 'Wells',
      selector: (row) => row.wells.length,
      sortable: false,
    },



  ];


  if (['0', '1'].includes(userType)) {
    columns.push({
      name: 'Action',
      selector: (row) => (
        <div className='d-flex gap-2'>
          <button onClick={() => {
            setSelectedItem(row);
            setEditModal(true);
          }} className='btn btn-primary'><CiEdit /> </button>
          <button onClick={() => setDeleteModal(true)} className='btn btn-danger'><MdOutlineDelete /></button>
        </div>
      ),
      sortable: false,
    });
  }







  const [search, setSearch] = useState({
    name: '',
    companyId: '',
  });



  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);


  return (
    <>

      <div className='row mb-2'>

        {userType != 2 && (
          <div className='max-300'>
            <h6>Company</h6>
            <CompanySelect setCompany={(e) => setSearch(prevState => ({
              ...prevState,
              companyId: e
            }))} />
          </div>
        )}

        <div className='max-300'>

          <h6>Lease</h6>
          <Form.Control type="text" placeholder='Lease name' value={search.name}
            onChange={(e) => setSearch(prevState => ({
              ...prevState,
              name: e.target.value
            }))}

          />
        </div>

      </div>


    
      {['0', '1'].includes(userType) && (
        <div className='text-end mb-3'>
          <button onClick={() => setAddModal(true)} className='btn btn-primary'>Add lease</button>
        </div>
      )}


      <TableComponent
        title="Leases"
        endPoint={ENDPOINTS.LeasePaginated}
        headers={columns}
        reloadData={reloadData}
        searchObject={search}
      />

      <CreateLease
        show={addModal}
        hideModal={() => setAddModal(false)}
        reloadData={() => {
          setAddModal(false)
          setReloadData(!reloadData)
        }}

      />
      <UpdateLease
        show={editModal}
        hideModal={() => setEditModal(false)}
        reloadData={() => {
          setEditModal(false)
          setReloadData(!reloadData)
        }}
        selectedItem={selectedItem} />

      <DeleteItemModal
        url={ENDPOINTS.LeaseDelete}
        body={{
          leaseId: selectedItem.id
        }}
        reloadData={() => {
          setDeleteModal(false)
          setReloadData(!reloadData)
        }}


        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to delete ${selectedItem.name} lease?`}
        title="Delete Lease" />

    </>
  )
}

export default Leases