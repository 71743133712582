import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { MdDelete } from "react-icons/md";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import GearboxBrakeForm from '../components/global/GearboxBrakeForm';
import MiscComponentsForm from '../components/global/MiscComponentsForm';
import ServiceComponentForm from '../components/global/ServiceComponentForm';
import ServiceInfoForm from '../components/global/ServiceInfoForm';
import ServiceMotorInformation from '../components/global/ServiceMotorInformation';
import ServiceUnitInformation from '../components/global/ServiceUnitInformation';
import { StructureComponentForm } from '../components/global/StructureComponentForm';
import WristPinComponentForm from '../components/global/WristPinComponentForm';
import { apiGetAuth, apiPost } from '../util/ApiRequest';
import { BASE_IMG_URL, ENDPOINTS } from '../util/EndPoint';
import { isLoaderState, snakeBarState } from '../util/RecoilStore';



const UpdateService = () => {
  const navigate = useNavigate();

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
  const [singleWell, setSingleWell] = useState({});
  const [singleServices, setSingleServices] = useState({});



  const [searchParams] = useSearchParams();
  const [wellId, setWellId] = useState(searchParams.get('wellId'));
  const [serviceId, setServiceId] = useState(searchParams.get('serviceId'));




  const [files, setFiles] = useState([]);


  useEffect(() => {
    if (wellId) {
      getSingleWell(wellId)
    }
  }, [wellId]);

  useEffect(() => {
    if (serviceId) {
      getSingleService(serviceId)
    }
  }, [serviceId]);






  const getSingleWell = (wellId) => {
    setIsLoaderInfo(true);
    apiGetAuth(
      ENDPOINTS.WellSingle + wellId,
      (res) => {
        setSingleWell(res);
        setIsLoaderInfo(false);
      },
      (error) => {
        console.log(error, ' error');
        setIsLoaderInfo(false);
      },
    );
  };


  const getSingleService = (ServiceId) => {
    setIsLoaderInfo(true);
    apiGetAuth(
      ENDPOINTS.ServiceSingle + ServiceId,
      (res) => {
        setSingleServices(res);
        setIsLoaderInfo(false);
      },
      (error) => {
        console.log(error, ' error');
        setIsLoaderInfo(false);
      },
    );
  };






  const handleSubmit = (event) => {
    event.preventDefault();
    const body = new FormData(event.target);
    setIsLoaderInfo(true)
    apiPost(
      ENDPOINTS.ServiceUpdate,
      body,
      (res) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "successfully  login",
        });
        setIsLoaderInfo(false)
        navigate(`/admin/service-records/${wellId}`)


      },
      (error) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
        });
        setIsLoaderInfo(false)
      }
    );
  };

  const deleteServiceImage = (filePath) => {
    setIsLoaderInfo(true)
    apiPost(
      ENDPOINTS.ServiceDeleteImage,
      {
        serviceId: serviceId,
        filePath: filePath
      },
      (res) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "successfully  deleted",
        });
        setIsLoaderInfo(false)
        getSingleService(serviceId);
      },
      (error) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
        });
        setIsLoaderInfo(false)
      }
    );
  };


  const onFileSelect = (e) => {
    setFiles([...files, ...e?.target?.files]);
  }

  return (
    <div className='container-fluid bg-white' >
      <div >

        <h3 className='text-center mb-4'>Update Services </h3>

        <form onSubmit={handleSubmit}>

          <div className='container custom-overly'>

            {singleWell && (
              <ServiceInfoForm isCreated={false} wellObj={singleWell} />
            )}
            {singleServices && (
              <ServiceUnitInformation isCreated={false} unitInfo={singleServices?.unitInfo} />
            )}
            {singleServices && (
              <ServiceMotorInformation isCreated={false} motorInfo={singleServices?.motorInfo} />
            )}

            {singleServices && (
              < ServiceComponentForm isCreated={false} bearing={singleServices?.bearing} />
            )}

            {singleServices && (
              <WristPinComponentForm isCreated={false} wristPin={singleServices?.wristPin} />
            )}

            {singleServices && (
              <GearboxBrakeForm
                isCreated={false}
                brake={singleServices?.brake}
                gearbox={singleServices?.gearbox} />
            )}

            {singleServices && (
              <MiscComponentsForm isCreated={false} miscComponents={singleServices?.miscComponents} />
            )}

            {singleServices && (
              <StructureComponentForm isCreated={false} structure={singleServices?.structure} />
            )}

            <div className='container  mt-5  p-5 rounded-2 custom-boxshedow'>
              <h5 className='text-center'>Technician Comments </h5>
              <div className='row'>
                <div className='col-md-12 col-sm-12'>
                  <div className=' mb-3'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Comments</Form.Label>
                      <Form.Control
                        name='technicianComments'
                        required={true}
                        defaultValue={singleServices?.technicianComments}
                        placeholder='Comments' as="textarea" rows={3} />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            <input type='hidden' value={singleServices?.id} name='serviceId' />

            <div className='container  mt-5  p-5 rounded-2 custom-boxshedow'>

              <div className='row mb-3'>
                {singleServices?.photos?.map((file, index) => (
                  <div key={index} className='col-lg-3 col-md-4 col-sm-12' >
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <button
                        type='button'
                        className='btn btn-danger'
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: '1'
                        }}
                        onClick={() => deleteServiceImage(file)}
                      >
                        <MdDelete />
                      </button>
                      <Image src={BASE_IMG_URL + file} thumbnail />
                    </div>
                  </div>
                ))}
              </div>

              <Form.Group controlId="formFileMultiple" className="mb-3">
                <Form.Label>Photo </Form.Label>
                <Form.Control type="file" multiple required={false} onChange={onFileSelect} name={'media'} />
              </Form.Group>

              <Row className="px-3">
                {files.map((file, index) => {
                  return <Col key={index} className="mt-3 mr-2 border px-2 py-1" sm={2}
                  >
                    <i className="fa fa-times float-right" aria-hidden="true"
                      onClick={() => {
                        let newfiles = [...files]
                        newfiles.splice(index, 1)
                        setFiles([...newfiles])
                      }}
                    ></i>
                    {file.name}

                  </Col>
                })}
              </Row>
            </div>

            <div className='container  mt-5  p-5 rounded-2  text-end'>
              <button className='btn btn-primary'>Update</button>
            </div>

          </div>
        </form>

      </div>
    </div>
  )
}

export default UpdateService