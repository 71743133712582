import moment from "moment";

export const CheckImageExtensionAll = (filename) => {
    const imgType = ['txt', 'docx', 'pdf', 'apng', 'avif', 'gif', 'jpg', 'jpeg', 'png', 'svg', 'webp', 'mp4', 'wewbm', 'ogg'];
    var fileType = filename['name'].split('.').pop();
    const acceptFiles = imgType.includes(fileType)
    return acceptFiles;
};



export const checkUserType = (type) => {
    if (type == 0) {
        return 'Admin';
    }
    if (type == 1) {
        return 'Technician';
    }
    return 'User';
};

export const DateFormatWithDash = date => moment.parseZone(date).format('MM-DD-YYYY');

export const isObjectEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0;
  };

  export const checkArrayType = (array) => {
    if (array?.length === 0) {
        return "empty";
      }
      
      if (array?.every(item => typeof item === "string")) {
        return "strings";
      }
      
      if (array?.every(item => typeof item === "object" && item !== null && !Array.isArray(item))) {
        return "objects";
      }
  };
  export const checkType = (input) => {
    if (typeof input === "string") {
        return "string";
      }
      
      if (Array.isArray(input)) {
        return "array";
      }
      
      if (typeof input === "object" && input !== null && !Array.isArray(input)) {
        return "json";
      }
  };