import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Image from 'react-bootstrap/Image';
import { FaEyeSlash } from "react-icons/fa";
import { Outlet } from "react-router-dom";
import { useRecoilState } from "recoil";
import logoImage from "../../assets/images/logo.png";
import { apiPost } from "../../util/ApiRequest";
import { ENDPOINTS } from "../../util/EndPoint";
import { isLoaderState, snakeBarState, toggledState } from "../../util/RecoilStore";
import SidebarCustom from "../sidebar/Sidebar";

const AdminLayout = (props) => {

  const [toggledInfo, setToggledInfo] = useRecoilState(toggledState);
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
  const [modalShow, setModalShow] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewPasswordConfirm, setViewPasswordConfirm] = useState(false);


  const [user, setUser] = useState({});
  useEffect(() => {
    const userLocal = JSON.parse(localStorage.getItem('user'));
    if (userLocal) {
      setUser(userLocal);
    }
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();
    const body = new FormData(event.target);
      setIsLoaderInfo(true)
      apiPost(
        ENDPOINTS.UserChangePassword,
        body,
        (res) => {
    
          setSnackBarInfo({
            snackStatus: true,
            snackColor: "bg-success",
            snackMsg: "successfully Updated JSA",
          });
          setIsLoaderInfo(false)
          setModalShow(false)
        },
        (error) => {
          setSnackBarInfo({
            snackStatus: true,
            snackColor: "bg-danger",
            snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
          });
          setIsLoaderInfo(false)
          
        }
      );
    
  }



  return (
    <>
      <div>

        <div className="bg-primary">
          <div className="header-logos">
            <div>
              <Image src={logoImage} alt="logo" width={160} height={60} />
            </div>
            <h5 className="text-white mb-0">Genesis Machine & Fabrication Portal</h5>
            <h4 className="user-profile-avatar">
              <DropdownButton id="dropdown-item-button" title={`${user?.userName}`}>
                <Dropdown.Item as="button" onClick={() => setModalShow(true)}>Reset password</Dropdown.Item>
              </DropdownButton>

            </h4>

          </div>


        </div>
        <div className="main-layout">
          <SidebarCustom />
          <main className={` p-4 ${!toggledInfo ? 'main-content' : 'main-content-close-sidebar'} `}>
            <Outlet />
          </main>
        </div>
      </div >


      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reset password</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form  onSubmit={handleSubmit}>

            <div className="position-relative">
              <Form.Group className="mb-2">
                <Form.Label className="mb-0 poppins-light text-dark ">
                  Password
                </Form.Label>

                <Form.Control
                  type={viewPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                />

                <button
                  onClick={() => setViewPassword(!viewPassword)}
                  className="no-btn-structure show-hide-btn"
                  type="button"
                >
                  <span className="text-primary">
                    <FaEyeSlash />
                  </span>
                </button>
              </Form.Group>


            </div>

            <div className="position-relative">
              <Form.Group className="mb-2">
                <Form.Label className="mb-0 poppins-light text-dark ">
                Password confirm
                </Form.Label>

                <Form.Control
                  type={viewPasswordConfirm ? "text" : "password"}
                  name="confirm_password"
                  placeholder="Password"
                />

                <button
                  onClick={() => setViewPasswordConfirm(!viewPasswordConfirm)}
                  className="no-btn-structure show-hide-btn"
                  type="button"
                >
                  <span className="text-primary">
                    <FaEyeSlash />
                  </span>
                </button>
              </Form.Group>
              <button className="btn btn-primary mt-2" type="submit">
              submit
            </button>

            </div>
          </form>


        </Modal.Body>
     
      </Modal >
    </>
  );
};

export default AdminLayout;
