import React, { useEffect, useState } from 'react';
import { FaUnlockAlt } from 'react-icons/fa';
import Status from '../components/global/Status';
const UsersDetail = () => {
    const [userId, setUserId] = useState(0);
    const [userObj, setUserObj] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        let userId = /[^/]*$/.exec(window.location.href)[0];
        // setUserId(userId);
        // getUserInfo(userId);
    }, []);

    // const getUserInfo = (userId) => {
    //     let body = {
    //         user_id: userId,
    //     };
    //     setLoader(true);
    //     apiPost(
    //         ENDPOINTS.UserDetails,
    //         body,
    //         (res) => {
    //             setUserObj(res.data);
    //             setLoader(false);
    //         },
    //         (error) => {
    //             console.log(error, ' error');
    //             setLoader(false);
    //         },
    //     );
    // };

    return (
        <>
            <div>
                <div className='container'>
                    <div className='user-profile'>
                        <h4 className='text-center mb-2'>User Detail</h4>
                        <div className='user-profile-items'>
                            <h4>First Name : </h4>
                            <h5>First Name</h5>
                        </div>

                        <div className='user-profile-items'>
                            <h4>Last  Name : </h4>
                            <h5>Last  Name</h5>
                        </div>

                        <div className='user-profile-items'>
                            <h4>Username : </h4>
                            <h5>Username</h5>
                        </div>

                        <div className='user-profile-items'>
                            <h4>Email : </h4>
                            <h5>Email@gmail.com</h5>
                        </div>

                        <div className='user-profile-items'>
                            <h4>Company : </h4>
                            <h5>Email@gmail.com</h5>
                        </div>

                        <div className='user-profile-items'>
                            <h4>User Type : </h4>
                            <h5>Admin</h5>
                        </div>

                        <div className='user-profile-items'>
                            <h4>Status : </h4>
                            <h5><Status status="active" /></h5>
                        </div>

                        <div className='user-profile-items'>
                            <h4>PW reset</h4>
                            <h5> <button className='btn  icon-btn btn-secondary'><FaUnlockAlt /> </button></h5>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default UsersDetail;
