import moment from 'moment';
import React from 'react';

const CustomDateInput = ({
  label,
  placeholder,
  type,
  required,
  name,
  defaultValue,
  readOnly,

}) => {
  return (
    <div>
      <label className="mb-2">{label}</label>
      <input

        className={`form-control ${readOnly ? 'read-only-custom' : ''}`}
        placeholder={placeholder}
        type={type}
        name={name}
        defaultValue={defaultValue}
        required={required}
        readOnly={readOnly}

      />
    </div>
  );
};

CustomDateInput.defaultProps = {
  type: 'date',
  defaultValue: moment.utc().format('YYYY-MM-DD'),
  required: false,
  readOnly: false,
};

export default CustomDateInput;