import React from 'react'

const CustomDateTimeInput = ({
  label,
  placeholder,
  type,
  required,
  name,
  defaultValue,
  readOnly,

}) => {



  return (
    <div>
      <label className="mb-2">{label}</label>

      <input

        className={`form-control ${readOnly ? 'read-only-custom' : ''}`}
        placeholder={placeholder}
        name={name}
        defaultValue={defaultValue}
        required={required}
        readOnly={readOnly}
        // onChange={(e) => setValue(e.target.value)}
        type="datetime-local" />

    </div>
  )
}

CustomDateTimeInput.defaultProps = {
  required: false,
  readOnly: false,
}

export default CustomDateTimeInput