import React from 'react';


export const UserAvatar = ({ user }) => {
    return (
        <div className='d-flex gap-2 border border-primary p-2 rounded-2'>
            <h4 className="px-4 py-3 rounded-5 bg-primary text-white mb-0">{user?.firstName?.charAt(0)}</h4>
            <div className="">
                <h5 className='mb-1'>{user?.firstName} {user?.lastName}</h5>
                <h6 className='mb-0'>{user?.email}</h6>
            </div>
        </div>

    )
}
