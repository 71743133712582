import React from 'react';
import Form from 'react-bootstrap/Form';


const NeedsAdjustmentOrOkSelect = ({ label, name, setValue, defaultValue }) => {
    return (
        <>
            <label className="mb-2">{label}</label>
            <Form.Select onChange={(e) => setValue(e.target.value)} name={name} defaultValue={defaultValue}
            >
                <option value="Ok">Ok</option>
                <option value="Needs Adjustment">Needs Adjustment</option>
            </Form.Select>
        </>
    )
}

export default NeedsAdjustmentOrOkSelect