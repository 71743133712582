import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { useRecoilState } from 'recoil';
import { apiPost } from '../util/ApiRequest';
import { BASE_IMG_URL, ENDPOINTS } from '../util/EndPoint';
import { checkArrayType, checkType } from '../util/Helper';
import { isLoaderState } from '../util/RecoilStore';


const DetailJsa = () => {
    const [userId, setUserId] = useState(0);
    const [userObj, setUserObj] = useState({});
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);

    useEffect(() => {
        let userId = /[^/]*$/.exec(window.location.href)[0];
        setUserId(userId);
        getUserInfo(userId);
    }, []);

    const getUserInfo = (userId) => {
        setIsLoaderInfo(true)
        let body = {
            jsa_id: userId,
        };
        apiPost(
            ENDPOINTS.JsaSingle,
            body,
            (res) => {
                setUserObj(res.data);
                setIsLoaderInfo(false)
            },
            (error) => {
                console.log(error, 'error');

            },
        );
    };


    return (
        <div className='container-fluid'>

            <div className=''>
                <h3 className='mb-3'>JSA Detail</h3>
                <div className='row' >
                    <div className='col-md-12 col-lg-6'>
                        <Table striped bordered hover>
                            <tbody >
                                <tr className=''>
                                    <th>Date</th>
                                    <td>{userObj?.date}</td>
                                </tr>
                                <tr className=''>
                                    <th>Location</th>
                                    <td>{userObj?.location}</td>
                                </tr>
                                <tr className=''>
                                    <th>Start Time</th>
                                    <td>
                                        {userObj && userObj.startTime ? moment(userObj.startTime).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
                                    </td>
                                </tr>
                                <tr className=''>
                                    <th>Taligate Topic</th>
                                    <td>{userObj?.taligateTopic}</td>
                                </tr>
                                <tr className=''>
                                    <th>Emergency Response Site</th>
                                    <td>{userObj?.emergencySite}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className='col-md-12 col-lg-6'>
                        <Table striped bordered hover>
                            <tbody >
                                <tr className=''>
                                    <th>Lead Person/Operator:</th>
                                    <td>{userObj?.operator}</td>
                                </tr>
                                <tr className=''>
                                    <th>Activity</th>
                                    <td>{userObj?.activity}</td>
                                </tr>
                                <tr className=''>
                                    <th>Stop Time</th>
                                    <td>
                                        {userObj && userObj.stopTime ? moment(userObj.stopTime).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
                                    </td>
                                </tr>
                                <tr className=''>
                                    <th>Customer W/O</th>
                                    <td>{userObj?.customer}</td>
                                </tr>
                                <tr className=''>
                                    <th>Operator Name/Phone</th>
                                    <td>{userObj?.operatorPhone}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>


                <div className='row'>
                    <div className='col-12'>
                        <h3 className='bg-primary text-white font-14 p-3'>Do the following known hazards exists?</h3>
                    </div>
                    <div className='col-md-12 col-lg-6'>
                        <Table striped bordered hover>
                            <tbody >
                                <tr>
                                    <th>Line of Fire/Pinch Points</th>
                                    <td>{userObj?.hazards?.pinchPoints === "1" ? "yes" : "no"}</td>
                                </tr>
                                <tr>
                                    <th>Walking Working Surfaces</th>
                                    <td>{userObj?.hazards?.hotSurfaces === "1" ? "yes" : "no"}</td>
                                </tr>
                                <tr>
                                    <th>Vehicle / Hvy. Equip. Movement</th>
                                    <td>{userObj?.hazards?.equip === "1" ? "yes" : "no"}</td>
                                </tr>
                                <tr>
                                    <th>Hazardous Energy</th>
                                    <td>{userObj?.hazards?.hazardousEnergy === "1" ? "yes" : "no"}</td>
                                </tr>
                                <tr>
                                    <th>Rotating Machinery & Pwr. Tools</th>
                                    <td>{userObj?.hazards?.pwrTools === "1" ? "yes" : "no"}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className='col-md-12 col-lg-6'>
                        <Table striped bordered hover>
                            <tbody >
                                <tr>
                                    <th>Hazardous Atmosphere/Hot Surfaces</th>
                                    <td>{userObj?.hazards?.hotSurfaces === "1" ? "yes" : "no"}</td>
                                </tr>
                                <tr>
                                    <th>Power Lines</th>
                                    <td>{userObj?.hazards?.powerLines === "1" ? "yes" : "no"}</td>
                                </tr>
                                <tr>
                                    <th>Fall Potential</th>
                                    <td>{userObj?.hazards?.fallPotential === "1" ? "yes" : "no"}</td>
                                </tr>
                                <tr>
                                    <th>Moving Heavy Objects</th>
                                    <td>{userObj?.hazards?.heavyObjects === "1" ? "yes" : "no"}</td>
                                </tr>
                                <tr>
                                    <th>Other</th>
                                    <td>{userObj?.hazards?.other === "1" ? "yes" : "no"}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12'>
                        <h3 className='bg-primary text-white font-14 p-3'>The following checklist must be reviewed during a pre-job planning/tailgate meeting conducted at the work location. Any deficiencies shall be noted and this form will be kept on file in the office.</h3>
                    </div>
                    <div className='col-md-12 col-lg-6'>
                        <Table striped bordered hover>
                            <tbody >
                                <tr>
                                    <th>Proper Protective Equipment In Use</th>
                                    <td>{userObj?.hazards?.equipInUse === "1" ? "yes" : "no"}</td>
                                </tr>
                                <tr>
                                    <th>Fire Extinguisher(s) Checked</th>
                                    <td>{userObj?.hazards?.fireExtinguiser === "1" ? "yes" : "no"}</td>
                                </tr>
                                <tr>
                                    <th>H2S/TRI Monitor - OK</th>
                                    <td>{userObj?.hazards?.triMonitor === "1" ? "yes" : "no"}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className='col-md-12 col-lg-6'>
                        <Table striped bordered hover>
                            <tbody >
                                <tr>
                                    <th>Suction/discharge valves identified</th>
                                    <td>{userObj?.hazards?.valvesIdentified === "1" ? "yes" : "no"}</td>
                                </tr>
                                <tr>
                                    <th>Housekeeping —OK</th>
                                    <td>{userObj?.hazards?.houseKeeping === "1" ? "yes" : "no"}</td>
                                </tr>
                                <tr>
                                    <th>Barricades and/or Cones in place</th>
                                    <td>{userObj?.hazards?.barricades === "1" ? "yes" : "no"}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12'>
                        <h3 className='bg-primary text-white font-14 p-3'>Comments</h3>
                    </div>
                    <div className='col-md-12 col-lg-12'>
                        <Table striped bordered hover>
                            <tbody >
                                <tr>
                                    <th>{userObj?.comments}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                </div>


                <div className='row'>
                    <div className='col-12'>
                        <h3 className='bg-primary text-white font-14 p-3'>In the box below, diagram the work site. Indicate the approx. location of any overhead power lines, steam or flow lines, vehicle(s) location(s), and any other potential or additional hazards</h3>
                    </div>
                    {userObj?.sign != null && (
                        <div className='col-md-12 col-lg-12 mb-2 d-flex justify-content-center flex-wrap gap-2'>
                            {JSON.parse(userObj?.sign)?.length > 0 &&
                                JSON.parse(userObj?.sign).map((item, index) => (
                                    <>
                                        {checkArrayType(JSON.parse(userObj?.sign)) == "strings" && (
                                            <Image key={index} className='border border-primary sign-image' src={BASE_IMG_URL + item} />
                                        )}
                                        {checkArrayType(JSON.parse(userObj?.sign)) == "objects" && (
                                            <Image key={index} className='border border-primary sign-image' src={BASE_IMG_URL + item?.path} />
                                        )}
                                    </>
                                ))}
                        </div>
                    )}

                </div>

                <div className='row'>
                    <div className='col-12'>
                        <h3 className='bg-primary text-white font-14 p-3'>I hereby acknowledge that I have reviewed this JSA/JSP and agree to follow all of the EHS requirements.</h3>
                    </div>

                </div>
                {userObj?.diagram != null && (

                    <>
                        {checkType(userObj?.diagram) == "string" && (
                            <Image thumbnail className='border border-primary diagram-img-custom'
                                src={BASE_IMG_URL + userObj?.diagram} />
                        )}
                        {checkType(userObj?.diagram) == "array" && (
                            <Image thumbnail className='border border-primary diagram-img-custom'
                                src={BASE_IMG_URL + userObj?.diagram[0]?.path} />
                        )}
                    </>


                )}

            </div>
        </div>
    )
}

export default DetailJsa