import React from 'react';
import AtoDSelect from './AtoDSelect';

const ServiceComponentForm = ({ bearing, isCreated }) => {
    return (
        <div className='container  mt-5  p-5 rounded-2 custom-boxshedow'>
            <div className='row'>
                <div className='col-12  mb-3'>
                    <h4 className='text-center service-heading'>Components</h4>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <h5 className='text-center'>Saddle Bearing</h5>
                    <div className='mb-3'>
                        {(bearing || isCreated) && (
                            <AtoDSelect
                                label="Overall Condition"
                                setValue={(val) => console.log(val)}
                                name="bearing_saddle_overallCondition"
                                defaultValue={bearing?.saddle?.overallCondition}
                            />)}
                    </div>

                    <div className=' mb-3'>
                        {(bearing || isCreated) && (
                            <AtoDSelect label="Seal" setValue={(val) => console.log(val)}
                                name="bearing_saddle_seal"
                                defaultValue={bearing?.saddle?.seal}


                            />
                        )}

                    </div>

                    <div className=' mb-3'>
                        {(bearing || isCreated) && (
                            <AtoDSelect
                                label="Lines"
                                setValue={(val) => console.log(val)}
                                name="bearing_saddle_lines"
                                defaultValue={bearing?.saddle?.lines}
                            />)}
                    </div>



                </div>

                <div className='col-md-6 col-sm-12'>
                    <h5 className='text-center'>Equalizer Bearing</h5>

                    <div className='mb-3'>
                        {(bearing || isCreated) && (
                            <AtoDSelect
                                label="Overall Condition"
                                setValue={(val) => console.log(val)}
                                name="bearing_equalizer_overallCondition"
                                defaultValue={bearing?.equalizer?.overallCondition}

                            />)}
                    </div>

                    <div className=' mb-3'>
                        {(bearing || isCreated) && (
                            <AtoDSelect
                                label="Seal"
                                setValue={(val) => console.log(val)}
                                name="bearing_equalizer_seal"
                                defaultValue={bearing?.equalizer?.seal}

                            />

                        )}
                    </div>

                    <div className=' mb-3'>
                        {(bearing || isCreated) && (
                            <AtoDSelect
                                label="Lines"
                                setValue={(val) => console.log(val)}
                                name="bearing_equalizer_lines"
                                defaultValue={bearing?.equalizer?.lines}

                            />
                        )}
                    </div>


                </div>

            </div>
        </div>
    )
}

export default ServiceComponentForm