import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { CgDanger } from 'react-icons/cg';
import { CiEdit } from "react-icons/ci";
import { FaUnlockAlt } from 'react-icons/fa';
import { MdOutlineDelete } from "react-icons/md";
import { useRecoilState } from 'recoil';
import CreateUser from '../components/forms/CreateUser';
import DeleteItemModal from '../components/forms/DeleteItemModal';
import UpdateUser from '../components/forms/UpdateUser';
import CompanySelect from '../components/global/CompanySelect';
import Status from '../components/global/Status';
import TableComponent from '../components/global/TableComponent';
import UserTypeSelect from '../components/global/UserTypeSelect';
import { apiPost } from '../util/ApiRequest';
import { ENDPOINTS } from '../util/EndPoint';
import { checkUserType } from '../util/Helper';
import { isLoaderState, snakeBarState } from '../util/RecoilStore';


const Users = () => {


  const [resetPassword, setResetPassword] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [reloadData, setReloadData] = useState(false);
  const [search, setSearch] = useState({
    firstName: '',
    lastName: '',
    email: '',
    userName: '',
    orderByColumn: '',
    orderBy: true,
    companyId: '',
    userType: null,
  });




  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);


  const [userType, setUserType] = useState('');
  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, []);







  const columns = [

    {
      name: 'company',
      selector: (row) => row?.company ? row?.company?.name : '-',
      key: 'company',
      sortable: true,
    },

    {
      name: 'User type',
      selector: (row) => <span> {checkUserType(row?.userType)}</span>,
      sortable: true,
    },
    {
      name: 'First name',
      selector: (row) => row.firstName,
      key: 'firstName',
      sortable: true,
    },
    {
      name: 'Last name',
      selector: (row) => row.lastName,
      key: 'lastName',
      sortable: true,
    },

    {
      name: 'Email',
      selector: (row) => row.email,
      key: 'email',
      sortable: true,
    },

    {
      name: 'user name',
      selector: (row) => row.userName,
      key: 'userName',
      sortable: true,
    },
    {
      name: 'status',
      selector: (row) => <Status status={row.isActive ? 'active' : 'deactive'} />,
      key: 'userName',
      sortable: true,
    },

    {
      name: 'Action',
      selector: (row) =>
        <div className='d-flex gap-2'>
          <button className='btn  icon-btn btn-secondary me-2'
          
          onClick={() => {
            setSelectedItem(row)
            setResetPassword(true)
          }}
          ><FaUnlockAlt /> </button>
          <button onClick={() => {
            setSelectedItem(row)
            setEditModal(true)
          }} className='btn me-2 icon-btn btn-primary'><CiEdit />
          </button>
          <button onClick={() => {
            setSelectedItem(row)
            setDeleteModal(true)
          }} className='btn icon-btn btn-danger'><MdOutlineDelete /></button>
        </div>,
      sortable: false,
    },


  ];








  const resetPasswordHandle = () => {
    setIsLoaderInfo(true)
    apiPost(
      ENDPOINTS.UserResetPassword,
      {userId:selectedItem.id},
      (res) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "successfully",
        });
        setIsLoaderInfo(false)
        setResetPassword(false)
        setReloadData(!reloadData)

      },
      (error) => {
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg:  "there is an error plz try ",
        });
        
        setIsLoaderInfo(false)
      }
    );
  };

  return (
    <>

      <div className='paginated-table'>
        <div className=' d-flex gap-3 mt-3'>
          <div className='max-300 mb-3'>

            <h6>Company</h6>
            <CompanySelect setCompany={(e) => setSearch(prevState => ({
              ...prevState,
              companyId: e
            }))} />


            <h6 className='mt-3'>User type </h6>
            <UserTypeSelect
              setUserType={(e) => setSearch(prevState => ({
                ...prevState,
                userType: e
              }))}

            />

          </div>

          <div className='max-300 mb-3'>
            <h6 className=''>first Name </h6>
            <Form.Control type="text" placeholder='First name' value={search.firstName}
              onChange={(e) => setSearch(prevState => ({
                ...prevState,
                firstName: e.target.value
              }))}

            />


            <h6 className='mt-3'>Last name</h6>
            <Form.Control type="text" placeholder='First name' value={search.lastName}
              onChange={(e) => setSearch(prevState => ({
                ...prevState,
                lastName: e.target.value
              }))}

            />

          </div>
          <div className='max-300 mb-3'>


            <h6 className=''>User name</h6>
            <Form.Control type="text" placeholder='user name' value={search.userName}
              onChange={(e) => setSearch(prevState => ({
                ...prevState,
                userName: e.target.value
              }))}
            />

            <h6 className='mt-3'>Email</h6>
            <Form.Control type="text" placeholder='Email' value={search.email}
              onChange={(e) => setSearch(prevState => ({
                ...prevState,
                email: e.target.value
              }))}
            />

          </div>
        </div>


        {userType == 0 && (
          <div className='text-end mb-3'>
            <button onClick={() => setAddModal(true)} className='btn btn-primary'>Add User</button>
          </div>
        )}



        <div className='paginated-table-inner  table-responsive '>

          <TableComponent
            title="Users"
            endPoint={ENDPOINTS.UserPaginated}
            headers={columns}
            reloadData={reloadData}
            searchObject={search}
          />

        </div>
      </div>

      <CreateUser
        show={addModal}
        hideModal={() => setAddModal(false)}
        reloadData={() => {
          setAddModal(false)
          setReloadData(!reloadData);
        }}
      />
      <UpdateUser
        show={editModal}
        hideModal={() => setEditModal(false)}
        reloadData={() => {
          setEditModal(false)
          setReloadData(!reloadData);
        }}
        selectedItem={selectedItem}
      />
      <DeleteItemModal
        url={ENDPOINTS.UserDelete}
        body={{
          userId: selectedItem.id
        }}
        reloadData={() => {
          setDeleteModal(false)
          setReloadData(!reloadData);
        }}


        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to delete ${selectedItem.userName} User?`}
        title="Delete User" />



<Modal show={resetPassword} onHide={() => setResetPassword(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body> <div className="d-flex gap-2"><h4 className="mb-0 text-danger"><CgDanger /> </h4>
         <h6 className="my-auto">{`Do you want to Reset ${selectedItem?.firstName} ${selectedItem?.lastName} Password?`}</h6></div> </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setResetPassword(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => resetPasswordHandle()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  )
}

export default Users