import React from 'react'
import { Form } from 'react-bootstrap'
import AtoDSelect from './AtoDSelect'
import YesNoSelect from './YesNoSelect'


const GearboxBrakeForm = ({ gearbox, brake, isCreated }) => {
    return (
        <div className='container  mt-5  p-5 rounded-2 custom-boxshedow'>

            <div className='row'>

                <div className='col-lg-6 col-md-6 mb-3'>
                    <h5 className='text-center'>Gearbox</h5>

                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='mb-3'>
                                {(gearbox || isCreated) && (
                                    <AtoDSelect
                                        label="High"
                                        setValue={(val) => console.log(val)}
                                        name="gearbox_left_high"
                                        defaultValue={gearbox?.left?.high}
                                    />

                                )}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='mb-3'>

                                {(gearbox || isCreated) && (
                                    <AtoDSelect label="Int"
                                        setValue={(val) => console.log(val)}
                                        name="gearbox_left_int"
                                        defaultValue={gearbox?.left?.int}


                                    />
                                )}
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='mb-3'>
                                {(gearbox || isCreated) && (
                                    <AtoDSelect
                                        label="Slow Left Seals"
                                        setValue={(val) => console.log(val)}
                                        name="gearbox_left_low"
                                        defaultValue={gearbox?.left?.low}
                                    />
                                )}
                            </div>
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='mb-3'>
                                {(gearbox || isCreated) && (
                                    <AtoDSelect label="High"
                                        setValue={(val) => console.log(val)}
                                        name="gearbox_right_high"
                                        defaultValue={gearbox?.right?.high}

                                    />
                                )}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='mb-3'>
                                {(gearbox || isCreated) && (
                                    <AtoDSelect label="Int"
                                        setValue={(val) => console.log(val)}
                                        name="gearbox_right_int"
                                        defaultValue={gearbox?.right?.int}
                                    />
                                )}
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='mb-3'>
                                {(gearbox || isCreated) && (
                                    <AtoDSelect label="Slow Right Seals"
                                        setValue={(val) => console.log(val)}
                                        name="gearbox_right_low"
                                        defaultValue={gearbox?.right?.low}

                                    />
                                )}
                            </div>
                        </div>

                    </div>

                    <div className=' mb-3'>
                        {(gearbox || isCreated) && (
                            <YesNoSelect
                                name="gearbox_gearboxLidLeaking"

                                label="Gearbox Lid Leaking"
                                setValue={(val) => console.log(val)}
                                defaultValue={gearbox?.gearboxLidLeaking}
                            />
                        )}
                    </div>

                    <div className=' mb-3'>
                        {(gearbox || isCreated) && (

                            <>
                                <label className="mb-2">Gearbox Bolts</label>
                                <Form.Select
                                    defaultValue={gearbox?.gearboxBolts}
                                    name="gearbox_gearboxBolts"

                                >
                                    <option value="Tight  ">Tight</option>
                                    <option value="Broken See Comments">Broken See Comments</option>
                                </Form.Select>
                            </>
                        )}
                    </div>

                    <div className=' mb-3'>
                        {(gearbox || isCreated) && (

                            <>
                                <label className="mb-2">Oil Level</label>
                                <Form.Select
                                    name="gearbox_oilLevel"
                                    defaultValue={gearbox?.oilLevel}

                                >
                                    <option value="Full">Full</option>
                                    <option value="Low">Low</option>
                                </Form.Select>
                            </>
                        )}
                    </div>

                    <div className=' mb-3'>
                        {(gearbox || isCreated) && (
                            <>
                                <label className="mb-2">Oil Condition</label>
                                <Form.Select
                                    defaultValue={gearbox?.oilCondition}
                                    name="gearbox_oilCondition"
                                >
                                    <option value="Line New">Line New</option>
                                    <option value="Normal Operating Condition">Normal Operating Condition</option>
                                    <option value="Heavy Metal">Heavy Metal</option>
                                </Form.Select>
                            </>
                        )}
                    </div>

                    <div className=' mb-3'>
                        {(gearbox || isCreated) && (
                            <>
                                <label className="mb-2">Gearbox Sheave</label>
                                <Form.Select
                                    defaultValue={gearbox?.gearboxShave}
                                    name="gearbox_gearboxShave"
                                >
                                    <option value="Good">Good</option>
                                    <option value="Warm">Warm</option>
                                </Form.Select>
                            </>
                        )}
                    </div>

                </div>

                <div className='col-lg-6 col-md-6  mb-3' >
                    <h5 className='mb-3 text-center'>Brake</h5>
                    <div className=' mb-3'>
                        {(brake || isCreated) && (
                            <AtoDSelect
                                defaultValue={brake?.brake}

                                setValue={(val) => console.log(val)}
                                label="Brake" name="brake_brake" />
                        )}

                    </div>

                    <div className=' mb-3'>
                        {(brake || isCreated) && (
                            <AtoDSelect
                                defaultValue={brake?.bandCondition}

                                setValue={(val) => console.log(val)}
                                label="Band Condition"
                                name="brake_bandCondition"
                            />
                        )}

                    </div>
                </div>
            </div>
        </div >
    )
}

export default GearboxBrakeForm