import moment from 'moment';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import CreateWells from '../components/forms/CreateWells';
import TableComponent from '../components/global/TableComponent';
import { apiPost } from '../util/ApiRequest';
import { ENDPOINTS } from '../util/EndPoint';
import { isLoaderState } from '../util/RecoilStore';


const JSA = () => {
  const navigate = useNavigate();

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);



  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [reloadData, setReloadData] = useState(false);
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const handleClose = () => setDeleteModal(false);

  const deleteReport = () => {
    setIsLoaderInfo(true);
    apiPost(
      ENDPOINTS.JsaDelete,
      {
        jsa_id: selectedItem?.id,

      },
      (res) => {
        setReloadData(!reloadData)
        setIsLoaderInfo(false);
        setDeleteModal(false)
      },
      (error) => {
        console.log(error);
        setIsLoaderInfo(false);
        setDeleteModal(false)
      }
    );
  };


  const columns = [
    {
      name: 'Date',
      selector: (row) => moment(row?.date).format("YYYY-MM-DD"),
      sortable: false,
    },
    {
      name: 'Customer',
      selector: (row) => row?.customer,
      key: 'customer',
      sortable: true,
    },
    {
      name: 'Location',
      selector: (row) => row?.location,
      key: 'location',
      sortable: true,
    },
    {
      name: 'Lead Operator',
      selector: (row) => row?.operator,
      key: 'operator',
      sortable: true,
    },

    

    {
      name: 'Detail',
      selector: (row) => (
        <button className="btn btn-primary" onClick={() => {
          navigate(`/admin/jsa-detail/${row?.id}`);
        }}>
          detail
        </button>
      ),
      sortable: false,
    },
    {
      name: 'Edit',
      selector: (row) => (
        <button className="btn btn-secondary" onClick={() => {
          navigate(`/admin/update-jsa/${row?.id}`);
        }}>
          Edit
        </button>
      ),
      sortable: false,
    },
    {
      name: 'Delete',
      selector: (row) => (
        <button className="btn btn-danger" onClick={() => {
          setDeleteModal(true)
          setSelectedItem(row)
        }}>
          Delete
        </button>
      ),
      sortable: false,
    },
  ];


  return (
    <>
      <div className='paginated-table'>
        <div className='d-flex flex-wrap justify-content-between mb-3'>
          <h3>JSA</h3>
          <button onClick={() => {
            navigate('/admin/create-jsa')
          }} className='btn btn-primary'>Add JSA</button>
        </div>

        <TableComponent
          title="JSA List"
          endPoint={ENDPOINTS.paginatedJsaList}
          headers={columns}
          reloadData={reloadData}
        />


      </div>
      <CreateWells show={addModal} hideModal={() => setAddModal(false)} />

      <div>
        <Modal size="sm" show={deleteModal} onHide={handleClose}>
          <Modal.Header closeButton className='bg-primary text-white'>
            <Modal.Title>Jsa Record Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to delete this record?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setAddModal(false)}>
              No
            </Button>
            <Button variant="danger" onClick={() => deleteReport('reject')}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/*

      <UpdateWells show={editModal} hideModal={() => setEditModal(false)} />
      <DeleteItemModal
        show={deleteModal}
        hideModal={() => setDeleteModal(false)} text="Do you want to delete JSA 1?" title="Delete JSA" /> */}
    </>
  )
}






export default JSA