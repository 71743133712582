import React from 'react';
import CrankHolePosition from './CrankHolePosition';
import CustomStateInput from './CustomStateInput';
import UnitTypeSelect from './UnitTypeSelect';


const ServiceUnitInformation = ({ unitInfo, isCreated }) => {
    return (
        <div className='container mt-5 p-5 rounded-2 custom-boxshedow'>

            <div className='row'>
                <div className='col-12 mb-3'>
                    <h4 className='text-center service-heading'>Unit Information</h4>
                </div>


                <div className='col-md-6 col-sm-12 mb-3'>

                    {(unitInfo || isCreated) && (
                        <CustomStateInput
                            setValue={(val) => console.log(val)}

                            label="Manufacturer"
                            placeholder="Manufacturer"
                            type="text"
                            required={true}
                            name="unitInfo_manafacturer"
                            defaultValue={unitInfo?.manafacturer}
                            
                        />
                    )}

                </div>

                <div className='col-md-6 col-sm-12 mb-3'>

                    {(unitInfo || isCreated) && (
                        <CustomStateInput
                            setValue={(val) => console.log(val)}

                            label="Post Serial Number"
                            placeholder="Post Serial Number"
                            type="text"
                            required={true}
                            name={"unitInfo_postSerialNumber"}
                            defaultValue={unitInfo?.postSerialNumber}
                        />
                    )}

                </div>

                <div className='col-md-6 col-sm-12 mb-3'>
                    {(unitInfo || isCreated) && (
                        <CustomStateInput
                            setValue={(val) => console.log(val)}

                            label="Designation"
                            placeholder="Designation"
                            type="text"
                            required={true}
                            name={"unitInfo_designation"}
                            defaultValue={unitInfo?.designation}

                        />
                    )}
                </div>


                <div className='col-md-6 col-sm-12 mb-3'>
                    {(unitInfo || isCreated) && (
                        <CustomStateInput
                            setValue={(val) => console.log(val)}

                            label="Gearbox Serial Number"
                            placeholder="Gearbox Serial Number"
                            type="text"
                            required={true}
                            name={"unitInfo_gearboxSerialNumber"}
                            defaultValue={unitInfo?.gearboxSerialNumber}
                        />
                    )}
                </div>


                <div className='col-md-6 col-sm-12 mb-3'>
                    {(unitInfo || isCreated) && (
                        <CustomStateInput
                            setValue={(val) => console.log(val)}

                            label="Left Side Counterweight"
                            placeholder="Left Side Counterweight"
                            type="text"
                            required={true}
                            name={"unitInfo_leftSideCounterweight"}
                            defaultValue={unitInfo?.leftSideCounterweight}
                        />
                    )}
                </div>


                <div className='col-md-6 col-sm-12 mb-3'>
                    {(unitInfo || isCreated) && (
                        <CustomStateInput
                            setValue={(val) => console.log(val)}

                            label="Right Side Counterweight"
                            placeholder="Right Side Counterweight"
                            type="text"
                            required={true}
                            name={"unitInfo_rightSideCounterweight"}
                            defaultValue={unitInfo?.rightSideCounterweight}
                        />
                    )}
                </div>

                <div className='col-md-6 col-sm-12 mb-3'>
                    {(unitInfo || isCreated) && (
                        <CustomStateInput
                            setValue={(val) => console.log(val)}

                            label="Gearbox Sheave Size"
                            placeholder="Gearbox Sheave Size"
                            type="text"
                            required={true}
                            name={"unitInfo_gearboxSheaveSize"}
                            defaultValue={unitInfo?.gearboxSheaveSize}
                        />
                    )}

                </div>

                <div className='col-md-6 col-sm-12 mb-3'>
                    {(unitInfo || isCreated) && (
                        <CustomStateInput
                            setValue={(val) => console.log(val)}

                            label="Gearbox Ratio"
                            placeholder="Gearbox Ratio"
                            type="text"
                            required={true}
                            name={"unitInfo_gearboxRatio"}
                            defaultValue={unitInfo?.gearboxRatio}

                        />
                    )}
                </div>

                <div className='col-md-6 col-sm-12 mb-3'>

                    {(unitInfo || isCreated) && (
                        <CustomStateInput
                            setValue={(val) => console.log(val)}

                            label="Concrete Pad Size"
                            placeholder="Concrete Pad Size"
                            type="text"
                            required={true}
                            name={"unitInfo_concretePadSize"}
                            defaultValue={unitInfo?.concretePadSize}

                        />
                    )}

                </div>

                <div className='col-md-6 col-sm-12 mb-3'>
                    {(unitInfo || isCreated) && (
                        <CustomStateInput
                            setValue={(val) => console.log(val)}

                            label="Unit Setback"
                            placeholder="Unit Setback"
                            type="text"
                            required={true}
                            name={"unitInfo_unitSetBack"}
                            defaultValue={unitInfo?.unitSetBack}


                        />
                    )}
                </div>

                <div className='col-md-6 col-sm-12 mb-3'>
                    {(unitInfo || isCreated) && (
                        <UnitTypeSelect

                            setValue={(val) => console.log(val)}
                            name={'unitInfo_unitType'}
                            label={'Unit Type'}
                            defaultValue={unitInfo?.unitType}
                        />

                    )}
                </div>

                <div className='col-md-6 col-sm-12 mb-3'>
                    {(unitInfo || isCreated) && (
                        <CrankHolePosition
                            setValue={(val) => console.log(val)}
                            name={'unitInfo_crankHolePosition'}
                            label={'Crank Hole Position'}
                            defaultValue={unitInfo?.crankHolePosition}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default ServiceUnitInformation