import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaEyeSlash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from "recoil";
import { apiPostWithoutAuth } from "../util/ApiRequest";
import { ENDPOINTS } from "../util/EndPoint";
import { isLoaderState, snakeBarState, userState } from "../util/RecoilStore";


const Login = () => {
  const navigate = useNavigate();

  //recoil state start
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
  const [userInfo, setUserInfo] = useRecoilState(userState);

  //recoil state end

  const [viewPassword, setViewPassword] = useState(false);
  const emailEl = React.useRef(null);
  const passwordEl = React.useRef(null);

  const [snackInfo, setSnackInfo] = useState({
    snackStatus: false,
    snackColor: "bg-primary",
    snackMsg: "",
  });

  const handleSubmit = (event) => {
    // navigate('/admin/dashboard')
    event.preventDefault();
    setIsLoaderInfo(true)

    const body = {
      email: emailEl.current.value,
      password: passwordEl.current.value,
    };
    apiPostWithoutAuth(
      ENDPOINTS.UserLogin,
      body,
      (res) => {

        localStorage.setItem("userType", res?.data?.user_details?.userType);
        localStorage.setItem("accessToken", res?.data?.token);
        localStorage.setItem("user", JSON.stringify(res?.data?.user_details));
        setUserInfo(res?.data?.user_details)
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "successfully  login",
        });
        setIsLoaderInfo(false)
        navigate('/admin/dashboard')

      },
      (error) => {
        console.log(error.message[0], "res--res---")
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: error?.message[0] ? error.message[0] : "there is an error",
        });
        setIsLoaderInfo(false)
      }
    );
  };

  return (
    <>
      <Container fluid className="back-image">
        <Container className="login-con-1">
          <Row className="login-con">
            <Col lg="12" md="12">
              <div className="login-form bg-primary p-4 rounded-3">
                <div>
                  <h1 className="mb-5 bahnschrift-regular text-white ">Login</h1>
                </div>
                <Form className="login-form bg-primary" onSubmit={handleSubmit}>
                  <Form.Group className="mb-4">
                    <Form.Label className="mb-0 poppins-light text-white">
                      Email address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      ref={emailEl}
                      placeholder="Enter email"
                    />
                  </Form.Group>
                  <div className="position-relative">
                    <Form.Group className="mb-4">
                      <Form.Label className="mb-0 poppins-light text-white">
                        Password
                      </Form.Label>

                      <Form.Control
                        type={viewPassword ? "text" : "password"}
                        name="password"
                        ref={passwordEl}
                        placeholder="Password"
                      />

                      <button
                        onClick={() => setViewPassword(!viewPassword)}
                        className="no-btn-structure show-hide-btn"
                        type="button"
                      >
                        <span className="text-primary">
                          <FaEyeSlash />
                        </span>
                      </button>
                    </Form.Group>
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn btn-secondary"
                  >
                    Login
                  </Button>
                </Form>


              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Login;
