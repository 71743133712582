import React from 'react';
import Form from 'react-bootstrap/Form';



const GoodBrokenMissingSelect = ({ label, name, setValue,  defaultValue }) => {
    return (
        <>
            <label className="mb-2">{label}</label>
            <Form.Select onChange={(e) => setValue(e.target.value)} name={name} defaultValue={defaultValue}>
                <option value="Good">Good</option>
                <option value="Broken">Broken</option>
                <option value="Missigns">Missing</option>
            </Form.Select>
        </>
    )
}

export default GoodBrokenMissingSelect