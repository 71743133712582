import React, { useEffect, useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { CiEdit } from "react-icons/ci";
import { FaEye } from "react-icons/fa6";
import { MdOutlineDelete } from "react-icons/md";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import logoImage from '../assets/images/logo.png';
import DeleteItemModal from '../components/forms/DeleteItemModal';
import { apiGetAuth, apiPost } from '../util/ApiRequest';
import { BASE_IMG_URL, ENDPOINTS } from '../util/EndPoint';
import { isLoaderState, snakeBarState } from '../util/RecoilStore';

const ServicesRecords = () => {

  const [index, setIndex] = useState(0);
  const [singeObj, setSingleObj] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  const navigate = useNavigate();

  const [userType, setUserType] = useState('');
  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, [userType]);


  const columns = [

    {
      name: 'No',
      selector: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: 'Well',
      selector: (row) => row?.well?.name,
      sortable: false,
    },
    {
      name: 'Lease',
      selector: (row) => row?.well?.lease?.name,
      sortable: false,
    },
    {
      name: 'company',
      selector: (row) => row?.well?.lease?.company?.name,
      sortable: false,
    },

    {
      name: 'Veiw',
      selector: (row, index) => <div className='d-flex gap-2'>
        <button onClick={() => { navigate(`/admin/service-detail/${row.id}`) }} className='btn  icon-btn btn-primary'><FaEye /> </button>
      </div>,
      sortable: false,
    },
  ];



  if (['0', '1'].includes(userType)) {
    columns.push({
      name: 'Action',
      selector: (row) => (
        <div className='d-flex gap-2'>
          <button onClick={() => { navigate(`/admin/update-service?wellId=${row?.well?.id}&serviceId=${row?.id}`) }} className='btn  icon-btn btn-primary'><CiEdit /> </button>
          <button onClick={() => {
            setSelectedItem(row)
            setIndex(index)
            setDeleteModal(true)

          }} className='btn icon-btn btn-danger'><MdOutlineDelete /></button>
        </div>
      ),
      sortable: false,
    });
  }



  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
  const [items, setItems] = useState([]);



  const { id } = useParams();
  const [itemId, setItemId] = useState(null);
  const [search, setSearch] = useState({
    wellId: id,
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    getService();
    setItemId(id);
  }, [itemId]);




  useEffect(() => {
    getService();
  }, [search]);


  const getService = () => {
    if (itemId) {
      setIsLoaderInfo(true);
      apiPost(
        ENDPOINTS.ServiceByWell,
        search,
        (res) => {
          console.log(res?.data)
          setItems(res?.data);
          getSingleWell(res?.data[0]?.id)
          setIsLoaderInfo(false);
        },
        (error) => {
          console.log(error);
          setIsLoaderInfo(false);
        }
      );
    }
  };



  const getSingleWell = (id) => {
    setIsLoaderInfo(true);
    apiGetAuth(
      ENDPOINTS.ServiceSingle + id,
      (res) => {
        console.log(res, "resresres")
        setSingleObj(res);
        setIsLoaderInfo(false);
      },
      (error) => {
        console.log(error, ' error');
        setIsLoaderInfo(false);
      },
    );
  };



  const handleSubmit = (event) => {

    event.preventDefault();
    const stopTime = document.getElementsByName('endDate')[0].value;
    const startTime = document.getElementsByName('startDate')[0].value;
    const body = {
      startDate: startTime,
      endDate: stopTime,
      wellId: itemId
    }
    if (stopTime < startTime) {
      setSnackBarInfo({
        snackStatus: true,
        snackColor: "bg-danger",
        snackMsg: "Stop time must be greater than start time",
      });
    } else {


      setIsLoaderInfo(true)
      apiPost(
        ENDPOINTS.ServiceDownload,
        body,
        (res) => {
          const url = BASE_IMG_URL + res?.data;
          if (url) {
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
          // console.log(res.data);
          setSnackBarInfo({
            snackStatus: true,
            snackColor: "bg-success",
            snackMsg: "successfully Updated JSA",
          });
          setIsLoaderInfo(false)

        },
        (error) => {
          setSnackBarInfo({
            snackStatus: true,
            snackColor: "bg-danger",
            snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
          });
          setIsLoaderInfo(false)
        }
      );
    }
  }


  return (
    <>

      {items?.length > 0 && (


        <>

          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 text-center '>
                <Image src={logoImage} alt="logo" className='mb-2' width={160} height={60} />

                <h3 className='font-18 mb-2'>{singeObj?.well?.lease?.company?.name}</h3>
                <h4 className='font-16 '>Well Number  : {singeObj?.well?.name}</h4>
                <h4 className='font-16'>Lease : {singeObj?.well?.lease?.name}</h4>
              </div>
            </div>

            <div className='row mt-1'>
              <div className='col-lg-3 col-md-12'>
                <form onSubmit={handleSubmit}>
                  <Form.Group className="  mb-3">
                    <h6>Stat date</h6>
                    <Form.Control

                      onChange={(e) => setSearch(prevState => ({
                        ...prevState,
                        startDate: e.target.value,
                      }))}
                      value={search.startDate}

                      name='startDate'
                      type="date" placeholder="Stat date" required />

                    <h6 className='mt-3'>End date</h6>
                    <Form.Control type="date"
                      onChange={(e) => setSearch(prevState => ({
                        ...prevState,
                        endDate: e.target.value,
                      }))}
                      value={search.endDate}

                      name='endDate'

                      required placeholder="End date" />

                    <button className='btn btn-primary mt-3 '>Download</button>

                  </Form.Group>
                </form>
              </div>

              <div className='col-lg-9 col-md-12 mt-3'>
                <div className='row'>

              
                <div className='col-lg-6 col-md-12'>
                  <h4 className='font-16'>Manufacturer : {singeObj?.unitInfo?.manafacturer}</h4>

                  <h4 className='font-16 mt-4'>Post Serial Number : {singeObj?.unitInfo?.postSerialNumber}</h4>
                  <h4 className='font-16'>Left Side Counterweight : {singeObj?.unitInfo?.leftSideCounterweight}</h4>


                  <h4 className='font-16 mt-4'>Concrete Pad Size   : {singeObj?.unitInfo?.concretePadSize}</h4>
                  <h4 className='font-16'>Gearbox Ratio   : {singeObj?.unitInfo?.gearboxRatio}</h4>

                  <h4 className='font-16  mt-4'>Horsepower Rating  : {singeObj?.motorInfo?.horsepowerRating}</h4>
                  <h4 className='font-16'>Sheave Size  : {singeObj?.unitInfo?.gearboxSheaveSize}</h4>

                  <h4 className='font-16  mt-4'>Unit Type  : {singeObj?.unitInfo?.unitType}</h4>
                  <h4 className='font-16'>Belt Size  : {singeObj?.motorInfo?.beltSize}</h4>
                </div>
                <div className='col-lg-6 col-md-12'>

                  <h4 className='font-16'>Designation  : {singeObj?.unitInfo?.designation}</h4>

                  <h4 className='font-16 mt-4'>Gearbox Serial Number  : {singeObj?.unitInfo?.gearboxSerialNumber}</h4>
                  <h4 className='font-16'>Right Side Counterweight  : {singeObj?.unitInfo?.rightSideCounterweight}</h4>

                  <h4 className='font-16  mt-4'>Unit Setback(Units are Inches) : {singeObj?.unitInfo?.unitSetBack}</h4>
                  <h4 className='font-16'>Gearbox Sheave Size : {singeObj?.unitInfo?.gearboxSheaveSize}</h4>

                  <h4 className='font-16 mt-4'>Motor RPM : {singeObj?.motorInfo?.motorRpm}</h4>
                  <h4 className='font-16 '>Motor Shaft Size : {singeObj?.motorInfo?.motorShaftSize}</h4>
                  <h4 className='font-16 mt-4'>Number of Belts  : {singeObj?.motorInfo?.numberOfBelts}</h4>
                </div>
                </div>


              </div>

            </div>

          </div>
        </>

      )}




      <div className='paginated-table'>

        <div className='text-end mb-3'>
          {['0'].includes(userType) && (

            <button className='btn btn-primary' onClick={() => { navigate(`/admin/create-service/${itemId}`) }} >Add service records</button>
          )}
        </div>


        <div className="my-dataTable">
          <div className="table-main-header bg-primary p-2">
            <h5 className="my-auto   text-secondary  p-2">{'Service records'}</h5>
          </div>

          <div className="table-responsive">
            <DataTable
              className=""
              columns={columns}
              data={items}
              pointerOnHover
              striped
              dense={false}
            />
          </div>


        </div >


        <DeleteItemModal
          url={ENDPOINTS.ServiceDelete}
          body={{
            serviceId: selectedItem.id
          }}
          reloadData={() => {
            setDeleteModal(false)
            getService();
          }}


          show={deleteModal}
          hideModal={() => setDeleteModal(false)}
          text={`Do you want to delete No ${index + 1} service?`}
          title="Delete service" />

      </div>

    </>
  )
}

export default ServicesRecords