import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import CompanyYearBar from "../components/charts/CompanyYearBar";
import LeaseYearBar from "../components/charts/LeaseYearBar";
import RiskChart from "../components/charts/RiskChart";
import UsersYearBar from "../components/charts/UsersYearBar";
import WellsYearBar from "../components/charts/WellsYearBar";

const Dashboard = () => {
  const [userType, setUserType] = useState("");
  useEffect(() => {
    const storedUserType = localStorage.getItem("userType");
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, [userType]);

  return (
    <>
      <Container fluid className="mt-4">
        <Row>
          <Col className="mb-4" md={12}>
            <Card>
              <RiskChart />
            </Card>
          </Col>
          {["0"].includes(userType) && (
            <Col className="mb-4" md={6}>
              <Card>
                <UsersYearBar />
              </Card>
            </Col>
          )}

          {["0"].includes(userType) && (
            <Col className="mb-4" md={6}>
              <Card>
                <CompanyYearBar />
              </Card>
            </Col>
          )}

          {["0"].includes(userType) && (
            <Col className="mb-4" md={6}>
              <Card>
                <WellsYearBar />
              </Card>
            </Col>
          )}
          {["0"].includes(userType) && (
            <Col className="mb-4" md={6}>
              <Card>
                <LeaseYearBar />
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
