import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import CustomCheckBox from '../components/global/CustomCheckBox';
import CustomDateInput from '../components/global/CustomDateInput';
import CustomDateTimeInput from '../components/global/CustomDateTimeInput';
import CustomInput from '../components/global/CustomInput';
import Draw from '../components/global/Draw';
import SignatureComponent from '../components/global/SignatureComponent';
import { apiPost } from '../util/ApiRequest';
import { ENDPOINTS } from '../util/EndPoint';
import { isLoaderState, snakeBarState } from '../util/RecoilStore';

const CreateJSA = () => {
    const navigate = useNavigate();
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

    const [listOfSigns, setListOfSigns] = useState(["0"])
    const [signData, setSignData] = useState([])
    const [diagramData, setDiagramData] = useState([])



    const [payload, setPayload] = useState({})
    const handleChange = (name, value) => {
        setPayload({
            ...payload, [name]: value
        })
    }


    const { id } = useParams();


    const signDataHandle = (data, index) => {
        const newData = [...signData];
        let found = newData.find(item => item.key == index);
        if (found) {
            found.data = data;
        }
        if (!found) {
            newData.push({ key: index, data: data });
        }
        setSignData(newData);
    }

    const diagramDataHandle = (data) => {
        const newData = [...diagramData];
        let found = newData.find(item => item.key == 1);
        if (found) {
            found.data = data;
        }
        if (!found) {
            newData.push({ key: 1, data: data });
        }
        setDiagramData(newData);
    }

    const handleSubmit = (event) => {
        const stopTime = document.getElementsByName('stopTime')[0].value;
        const startTime = document.getElementsByName('startTime')[0].value;

        event.preventDefault();

        if (stopTime < startTime) {
            setSnackBarInfo({
                snackStatus: true,
                snackColor: "bg-danger",
                snackMsg: "Stop time must be greater than start time",
            });
        } else {

            const body = new FormData(event.target);
            body.append('sign', JSON.stringify(signData));
            body.append('diagram', JSON.stringify(diagramData));
            setIsLoaderInfo(true)
            apiPost(
                ENDPOINTS.JsaCreate,
                body,
                (res) => {
                    setSnackBarInfo({
                        snackStatus: true,
                        snackColor: "bg-success",
                        snackMsg: "successfully created JSA",
                    });
                    setIsLoaderInfo(false)
                    navigate(`/admin/jsa`);
                    // reloadData();
                },
                (error) => {
                    setSnackBarInfo({
                        snackStatus: true,
                        snackColor: "bg-danger",
                        snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
                    });
                    setIsLoaderInfo(false)
                }
            );
        }

    };


    return (
        <div className='container-fluid p-4 custom-overly'>

            <form onSubmit={handleSubmit} className=''>
                <h3 className='text-center mb-4'>Create JSA</h3>
                <div className='row border border-primary px-3 py-4 mb-4 rounded-3 '>
                    <div className='col-lg-6 col-md-12 mb-2'>
                        <CustomInput
                            label="Lead Person/Operator"
                            placeholder="Lead Person/Operator"
                            type="text"
                            required={true}
                            name="operator"
                        />
                    </div>

                    <div className='col-lg-6 col-md-12 mb-2'>
                        <CustomInput
                            label="Operator Name/Phone *"
                            placeholder="Operator Name/Phone *"
                            type="text"
                            required={true}
                            name="operatorPhone"
                        />
                    </div>
                </div>
                <div className='row border border-primary px-3 py-4 mb-4 rounded-3'>

                    <div className='col-lg-6 col-md-12 mb-2'>
                        <CustomDateInput
                            label="Date"
                            placeholder="Date"
                            type="date"
                            required={true}
                            name="date"
                        />

                    </div>



                    <div className='col-lg-6 col-md-12 mb-2'>
                        <CustomInput
                            label="Location"
                            placeholder="Location"
                            type="text"
                            required={true}
                            name="location"
                        />
                    </div>

                    <div className='col-lg-6 col-md-12 mb-2'>
                        <CustomInput
                            label="Activity"
                            placeholder="Activity"
                            type="text"
                            required={true}
                            name="activity"
                        />
                    </div>

                    <div className='col-lg-6 col-md-12 mb-2'>
                        <CustomDateTimeInput
                            label="Start Time"
                            placeholder="Start Time"
                            required={true}
                            name="startTime"
                        />
                    </div>

                    <div className='col-lg-6 col-md-12 mb-2'>
                        <CustomDateTimeInput
                            label="Stop Time"
                            placeholder="Stop Time"
                            required={true}
                            name="stopTime"
                        />
                    </div>

                    <div className='col-lg-6 col-md-12 mb-2'>
                        <CustomInput
                            label="Taligate Topic *"
                            placeholder="Taligate Topic *"
                            type="text"
                            required={true}
                            name="taligateTopic"
                        />
                    </div>

                    <div className='col-lg-6 col-md-12 mb-2'>
                        <CustomInput
                            label="Customer W/O *"
                            placeholder="Customer W/O *"
                            type="text"
                            required={true}
                            name="customer"
                        />
                    </div>

                    <div className='col-lg-6 col-md-12 mb-2'>
                        <CustomInput
                            label="Emergency Response Site *"
                            placeholder="Emergency Response Site *"
                            type="text"
                            required={true}
                            name="emergencySite"
                        />
                    </div>


                    <div className='col-12 mt-4'>
                        <h4>Do the following known hazards exists?</h4>
                    </div>



                </div>

                <div className="row border border-primary px-3 py-4 mb-4 rounded-3">
                    <CustomCheckBox
                        name="hazards_pinchPoints"
                        label="Line of Fire/Pinch Points"
                    />
                    <CustomCheckBox
                        name="hazards_hotSurfaces"
                        label="Hazardous Atmosphere/Hot Surfaces"
                    />
                    <CustomCheckBox
                        name="hazards_workingSurfaces"
                        label="Walking Working Surfaces"
                    />
                    <CustomCheckBox
                        name="hazards_powerLines"
                        label="Power Lines"
                    />
                    <CustomCheckBox
                        name="hazards_equip"
                        label="Vehicle / Hvy. Equip. Movement"
                    />
                    <CustomCheckBox
                        name="hazards_fallPotential"
                        label="Fall Potential"
                    />
                    <CustomCheckBox
                        name="hazards_hazardousEnergy"
                        label="Hazardous Energy"
                    />
                    <CustomCheckBox
                        name="hazards_heavyObjects"
                        label="Moving Heavy Objects"
                    />
                    <CustomCheckBox
                        name="hazards_pwrTools"
                        label="Rotating Machinery & Pwr. Tools"
                    />
                    <CustomCheckBox
                        name="hazards_other"
                        label="Other"
                    />
                </div>

                <div className='row  border border-primary px-3 py-4 mb-4 rounded-3'>
                    <div className='col-12'>
                        <h6>Hazard identification & Mitigation: Identify the hazard(s) by #. Clearly identify the hazard(s) & corrective action necessary to mitigate the hazard(s).</h6>
                        <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Comments</Form.Label>
                            <Form.Control
                                name='comments'
                                placeholder='Comments' as="textarea" rows={3} />
                        </Form.Group>
                        <h6>The following checklist must be reviewed during a pre-job planning/tailgate meeting conducted at the work location. Any deficiencies shall be noted and this form will be kept on file in the office.</h6>
                    </div>

                </div>

                <div className="row  border border-primary px-3 py-4 mb-4 rounded-3">

                    <CustomCheckBox
                        name="deficiencies_equipInUse"
                        label="Proper Protective Equipment In Use"
                    />
                    <CustomCheckBox
                        name="deficiencies_valvesIdentified"
                        label="Suction/discharge valves identified"
                    />

                    <CustomCheckBox
                        name="deficiencies_fireExtinguisher"
                        label="Fire Extinguisher(s) Checked"
                    />

                    <CustomCheckBox
                        name="deficiencies_houseKeeping"
                        label="Housekeeping —OK"
                    />

                    <CustomCheckBox
                        name="deficiencies_triMonitor"
                        label="H2S/TRI Monitor - OK"
                    />
                    <CustomCheckBox
                        name="deficiencies_barricades"
                        label=" Barricades and/or Cones in place"
                    />


                    <div className='col-12  mt-3'>
                        <h6>In the box below, diagram the work site. Indicate the approx. location of any overhead power lines, steam or flow lines, vehicle(s) location(s), and any other potential or additional hazards</h6>
                    </div>
                </div>
                {/* for diagram Start  */}
                <div className="row mt-3">
                    <div className='col-12'>
                        <h2>Diagram </h2>
                        <Draw
                            handleDiagramData={(data) => diagramDataHandle(data)}

                        />
                    </div>
                </div>
                {/* for diagram End  */}

                {/* for sign Start  */}
                <div className="row mt-3">
                    <div className='col-12'>
                        <div className='signature-com-box'>
                            {listOfSigns?.length > 0 &&
                                listOfSigns.map((item, index) => (
                                    <div key={index} className='signature-com'>
                                        <h2>Signature {index + 1}</h2>
                                        <SignatureComponent
                                            handleSignData={(data, index) => signDataHandle(data, index)}
                                            index={index}
                                        />
                                    </div>

                                ))}
                        </div>
                        <div className='text-center mb-4'>
                            <button type='button' className='btn btn-primary font-12' onClick={() => setListOfSigns([...listOfSigns, String(listOfSigns.length)])} >Add Signature</button>
                        </div>


                    </div>
                </div>
                {/* for sign End  */}



                <div className="row mt-3">
                    <div className='col-12 text-end'>
                        <button className='btn btn-danger me-2'>cancel</button>
                        <button className='btn btn-primary'>Add</button>
                    </div>
                </div>


            </form>
        </div>
    )
}

export default CreateJSA