import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';

const data = [
    { id: 1, name: 'John Doe', age: 30 },
    { id: 2, name: 'Jane Smith', age: 25 },
    { id: 3, name: 'Jane Smith', age: 25 },
    { id: 4, name: 'Jane Smith', age: 25 },
    // ... more data
];

const columns = [
    {
        name: 'ID',
        selector: (row) => row.id,
        sortable: false,
    },
    {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
    },
    {
        name: 'Age',
        selector: (row) => row.age,
        sortable: true,
    },
];

const SearchComponent = ({ filterText, onFilter }) => (
    <input
        type="text"
        value={filterText}
        onChange={(event) => onFilter(event.target.value)}
        placeholder="Search..."
        className='form-control'
    />
);

function Test () {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredData = useMemo(() => {
        return data.filter((row) => {
            const searchTextLower = searchTerm.toLowerCase();
            return (
                row.id.toString().toLowerCase().includes(searchTextLower) ||
                row.name.toLowerCase().includes(searchTextLower) ||
                row.age.toString().includes(searchTextLower)
            );
        });
    }, [data, searchTerm]);

    const handleSearch = (value) => {
        setSearchTerm(value.toLowerCase());
    };

    return (
        <div className='container mt-5'>
            <div className='text-center'>
                <SearchComponent filterText={searchTerm} onFilter={handleSearch} />
            </div>
            <DataTable
                className="my-dataTable"
                columns={columns}
                data={filteredData}
                pointerOnHover
                striped
                dense={false}
            />
        </div>
    );
}

export default Test;