import React from 'react'
import { Form } from 'react-bootstrap'
import AtoDSelect from './AtoDSelect'
import CustomStateInput from './CustomStateInput'
import NeedsAdjustmentOrOkSelect from './NeedsAdjustmentOrOkSelect'

export const StructureComponentForm = ({ structure, isCreated }) => {
    return (
        <div className='container  mt-5  p-5 rounded-2 custom-boxshedow'>
            <h5 className='text-center'>Structure </h5>
            <div className='row'>


                <div className='col-md-6 col-sm-12'>
                    <div className=' mb-3'>
                        {(structure || isCreated) && (
                            <NeedsAdjustmentOrOkSelect
                                setValue={(val) => console.log(val)}
                                label={"Unit Alignment"}
                                name="structure_unitAlignment"
                                defaultValue={structure?.unitAlignment}

                            />
                        )}
                    </div>
                </div>



                <div className='col-md-6 col-sm-12'>
                    <div className=' mb-3'>
                        {(structure || isCreated) && (
                            <NeedsAdjustmentOrOkSelect
                                setValue={(val) => console.log(val)}
                                defaultValue={structure?.unitLevel}

                                label={"Unit Level"}
                                name="structure_unitLevel" />
                        )}
                    </div>
                </div>


                <div className='col-md-6 col-sm-12'>
                    <div className=' mb-3'>
                        {(structure || isCreated) && (
                            <>
                                <label className="mb-2">Cracks</label>
                                <Form.Select
                                    defaultValue={structure?.cracks}
                                    name="structure_cracks"
                                >
                                    <option value="None">None</option>
                                    <option value="See Comments">See Comments</option>
                                </Form.Select>
                            </>
                        )}
                    </div>
                </div>


                <div className='col-md-6 col-sm-12'>
                    <div className=' mb-3'>
                        {(structure || isCreated) && (
                            <>
                                <label className="mb-2">Structural Bolts</label>
                                <Form.Select
                                    defaultValue={structure?.structureBolts}
                                    name="structure_structureBolts"
                                >
                                    <option value="OK">OK</option>
                                    <option value="Needs Adjustment">Needs Adjustment</option>
                                </Form.Select>
                            </>
                        )}
                    </div>
                </div>


                <div className='col-md-6 col-sm-12'>
                    <div className=' mb-3'>
                        {(structure || isCreated) && (
                            <>
                                <CustomStateInput
                                    setValue={(val) => console.log(val)}

                                    label="Pitman Arms"
                                    placeholder="Pitman Arms"
                                    type="text"
                                    required={true}
                                    name="structure_pitmanArms"
                                    defaultValue={structure?.pitmanArms}
                                />
                            </>
                        )}
                    </div>
                </div>

                <div className='col-md-6 col-sm-12'>
                    <div className=' mb-3'>
                        {(structure || isCreated) && (
                            <>
                                <AtoDSelect
                                    setValue={(val) => console.log(val)}
                                    name="structure_leftSide"
                                    defaultValue={structure?.leftSide}
                                    label="Left Side" />
                            </>
                        )}
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <div className=' mb-3'>
                        {(structure || isCreated) && (
                            <>
                                <AtoDSelect
                                    setValue={(val) => console.log(val)}
                                    name="structure_rightSide"
                                    label="Right Side"
                                    defaultValue={structure?.rightSide}

                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
