import React, { useRef, useState } from 'react';
import {
    ReactSketchCanvas
} from "react-sketch-canvas";

function Draw({handleDiagramData}) {
    const canvasRef = useRef(null);
    const [eraseMode, setEraseMode] = useState(false);
    const [strokeWidth, setStrokeWidth] = useState(5);
    const [strokeColor, setStrokeColor] = useState("#000000");


    const handleEraserClick = () => {
        setEraseMode(true);
        canvasRef.current?.eraseMode(true);
    };

    const handlePenClick = () => {
        setEraseMode(false);
        canvasRef.current?.eraseMode(false);
    };


    const handleClearClick = () => {
        canvasRef.current?.clearCanvas();
    };


    const handleStrokeColorChange = (event) => {
        setStrokeColor(event.target.value);
    };

    const handleStrokeWidthChange = (event) => {

        console.log(event.target.value);
        setStrokeWidth(event.target.value);
    };


    const handleExportImage = async () => {
        if (canvasRef.current) {
            const data = await canvasRef.current?.exportImage('png');
            handleDiagramData(data)
        }
    };
    return (
        <div>

            <div className="d-flex gap-2 justify-content-between mb-2 ">

                <div>
                    <input
                        type="color"
                        value={strokeColor}
                        onChange={handleStrokeColorChange}
                        className='me-2'
                    />
                    <input
                        disabled={eraseMode}
                        type="range"
                        className="form-range form-range-custom"
                        min="1"
                        max="20"
                        step="1"
                        id="strokeWidth"
                        value={strokeWidth}
                        onChange={handleStrokeWidthChange}
                    />

                </div>

                <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    onClick={handleClearClick}
                > Clear</button>

            </div>
            <ReactSketchCanvas
            className='drawing-canvas-custom'
                ref={canvasRef}
                allowOnlyPointerType="all"
                strokeWidth={strokeWidth}
                strokeColor={strokeColor}
                onChange={handleExportImage}
            />
        </div>
    );
}

export default Draw