import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { apiGetAuth } from '../util/ApiRequest';
import { BASE_IMG_URL, ENDPOINTS } from '../util/EndPoint';
import { isLoaderState, snakeBarState } from '../util/RecoilStore';

const ServiceDetail = () => {

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);


  const navigate = useNavigate();
  const { id } = useParams();
  const [itemId, setItemId] = useState(null);
  const [item, setItem] = useState({});


  useEffect(() => {
    service();
    setItemId(id);
  }, [itemId]);

  const service = () => {
    if (itemId) {
      setIsLoaderInfo(true);
      apiGetAuth(
        ENDPOINTS.ServiceSingle + itemId,
        (res) => {
          console.log(res?.data)
          setItem(res);
          setIsLoaderInfo(false);
        },
        (error) => {
          console.log(error);
          setIsLoaderInfo(false);
        }
      );
    }
  };



  return (

    <div className='container-fluid'>

      <div className='row mb-3'>
        <div className='col-12'>
          <h4>Service Record Detail</h4>
        </div>
      </div>
      <div className="p-5 card custom-overly">
        <div className="mb-4 card-deck">
          <div className="p-4 col">
            <div className="row mb-4">
              <div className="col-md-12">
                <h3 className="center bg-info p-2"> Info</h3>
              </div>
            </div>
            <div className="px-4 fs-18 row">
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">Customer:</label><span className="fw-400"> {item?.well?.lease?.company?.name}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Date:</label><span className="fw-400"> {moment(item?.date).utc().format('YYYY-MM-DD')}</span></div>
              </div>
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">Lease:</label><span className="fw-400"> {item?.well?.lease?.name}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Well Number:</label><span className="fw-400"> {item?.well?.name}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-deck">
          <div className="p-4 col">
            <div className="row mb-4">
              <div className="col-md-12">
                <h3 className="center bg-info p-2"> Unit Information</h3>
              </div>
            </div>
            <div className="px-4 fs-18 row">
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">Manufacturer:</label><span className="fw-400">{item?.unitInfo?.manafacturer}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Designation:</label><span className="fw-400"> {item?.unitInfo?.designation}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Left Side Counterweight:</label><span className="fw-400"> {item?.unitInfo?.leftSideCounterweight}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Gearbox Sheave Size:</label><span className="fw-400"> {item?.unitInfo?.gearboxSheaveSize}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Concrete Pad Size:</label><span className="fw-400"> {item?.unitInfo?.concretePadSize}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Unit Type:</label><span className="fw-400"> {item?.unitInfo?.unitType}</span></div>
              </div>
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">Post Serial Number:</label><span className="fw-400"> {item?.unitInfo?.postSerialNumber}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Gearbox Serial Number:</label><span className="fw-400"> {item?.unitInfo?.gearboxSerialNumber}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Right Side Counterweight:</label><span className="fw-400"> {item?.unitInfo?.rightSideCounterweight}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Gearbox Ratio:</label><span className="fw-400"> {item?.unitInfo?.gearboxRatio}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Unit Setback:</label><span className="fw-400"> {item?.unitInfo?.unitSetBack}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Crank Hole Position:</label><span className="fw-400"> {item?.unitInfo?.crankHolePosition}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-deck">
          <div className="p-4 col">
            <div className="row mb-4">
              <div className="col-md-12 bg-info p-2">
                <h3 className="center"> Motor Information</h3>
              </div>
            </div>
            <div className="px-4 fs-18 row">
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">Manufacturer:</label><span className="fw-400"> {item?.motorInfo?.manafacturer}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Horsepower Rating:</label><span className="fw-400"> {item?.motorInfo?.horsepowerRating}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Frame Size:</label><span className="fw-400"> {item?.motorInfo?.frameSize}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Sheave Size:</label><span className="fw-400"> {item?.motorInfo?.sheaveSize}</span></div>
              </div>
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">Motor RPM:</label><span className="fw-400"> {item?.motorInfo?.motorRpm}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Belt Size:</label><span className="fw-400"> {item?.motorInfo?.beltSize}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Number of Belts:</label><span className="fw-400"> {item?.motorInfo?.numberOfBelts}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Motor Shaft Size:</label><span className="fw-400"> {item?.motorInfo?.motorShaftSize}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-deck">
          <div className="p-4 col">
            <div className="row mb-4">
              <div className="col-md-12 bg-info p-2">
                <h3 className="center">Components</h3>
              </div>
            </div>
            <div className="px-4 fs-18 row">
              <div className="col-md-6">
                <h5 className="center fs-20 bg-light p-2 mt-3">Saddle Bearing</h5>
                <div className="mb-2"> <label className="font-bold-custom">Overall Condition:</label><span className="fw-400"> {item?.bearing?.saddle?.overallCondition}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Seal:</label><span className="fw-400"> {item?.bearing?.saddle?.lines}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Lines:</label><span className="fw-400"> {item?.bearing?.saddle?.overallCondition}</span></div>
              </div>
              <div className="col-md-6">
                <h5 className="center fs-20 bg-light p-2 mt-3">Equalizer Bearing</h5>
                <div className="mb-2"> <label className="font-bold-custom">Overall Condition:</label><span className="fw-400"> {item?.bearing?.equalizer?.overallCondition}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Seal:</label><span className="fw-400"> {item?.bearing?.equalizer?.lines}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Lines:</label><span className="fw-400"> {item?.bearing?.equalizer?.seal}</span></div>
              </div>
            </div>
            <div className="px-4 fs-18 row">
              <div className="col-md-6">
                <h5 className="center fs-20 bg-light p-2 mt-3">Left Side Wrist Pin</h5>
                <div className="mb-2"> <label className="font-bold-custom">Seal:</label><span className="fw-400">  {item?.wristPin?.left?.seal}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Bearing:</label><span className="fw-400">  {item?.wristPin?.left?.bearing}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Nut:</label><span className="fw-400">  {item?.wristPin?.left?.nut}</span></div>
              </div>
              <div className="col-md-6">
                <h5 className="center fs-20 bg-light p-2 mt-3">Right Side Wrist Pin</h5>
                <div className="mb-2"> <label className="font-bold-custom">Seal:</label><span className="fw-400">  {item?.wristPin?.right?.seal}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Bearing:</label><span className="fw-400">  {item?.wristPin?.right?.bearing}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Nut:</label><span className="fw-400">  {item?.wristPin?.right?.nut}</span></div>
              </div>
            </div>

            <div className="px-4 fs-18 row">
              <div className="col-12 col">
                <h5 className="center fs-20 bg-light p-2 mt-3">Gear Box</h5>
              </div>
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">High:</label><span className="fw-400"> {item?.gearbox?.left?.high}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Int:</label><span className="fw-400"> {item?.gearbox?.left?.int}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Slow Left Seals:</label><span className="fw-400"> {item?.gearbox?.left?.int}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Gearbox Lid Leaking:</label><span className="fw-400"> {item?.gearbox?.gearboxBolts}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Oil Level:</label><span className="fw-400"> {item?.gearbox?.oilLevel}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Gearbox Sheave:</label><span className="fw-400"> {item?.gearbox?.gearboxShave}</span></div>
              </div>
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">High:</label><span className="fw-400"> {item?.gearbox?.right?.high}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Int:</label><span className="fw-400"> {item?.gearbox?.right?.int}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Slow Right Seals:</label><span className="fw-400"> {item?.gearbox?.right?.low}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Gearbox Bolts:</label><span className="fw-400"> {item?.gearbox?.gearboxBolts}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Oil Condition:</label><span className="fw-400"> {item?.gearbox?.oilCondition}</span></div>
              </div>
            </div>

            <div className="px-4 fs-18 row">
              <div className="col-12 col">
                <h5 className="center fs-20 bg-light p-2 mt-3">Brake</h5>
              </div>
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">Brake:</label><span className="fw-400"> {item?.brake?.brake}</span></div>
              </div>
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">Band Condition:</label><span className="fw-400"> {item?.brake?.bandCondition}</span></div>
              </div>
            </div>
            <div className="px-4 fs-18 row">
              <div className="col-12 col">
                <h5 className="center fs-20 bg-light p-2 mt-3">Misc Components</h5>
              </div>
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">Bridle Cable:</label><span className="fw-400"> {item?.miscComponents?.bridleCable}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Carrier Bar:</label><span className="fw-400"> {item?.miscComponents?.carrierBar}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Ladder:</label><span className="fw-400"> {item?.miscComponents?.ladder}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Belt Condition:</label><span className="fw-400"> {item?.miscComponents?.beltCondition}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Base Condition:</label><span className="fw-400"> {item?.miscComponents?.baseCondition}</span></div>
              </div>
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">Tie Downs:</label><span className="fw-400"> {item?.miscComponents?.tieDowns}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Guards:</label><span className="fw-400"> {item?.miscComponents?.guards}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Well Signs:</label><span className="fw-400"> {item?.miscComponents?.bridleCable}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Rod Rotator Working (if Applicable):</label><span className="fw-400"> {item?.miscComponents?.bridleCable}</span></div>
              </div>
            </div>
            <div className="px-4 fs-18 row">
              <div className="col-12 col">
                <h5 className="center fs-20 bg-light p-2 mt-3">Structure</h5>
              </div>
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">Unit Alignment:</label><span className="fw-400">  {item?.structure?.unitAlignment}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Unit Level:</label><span className="fw-400">  {item?.structure?.unitLevel}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Cracks:</label><span className="fw-400">  {item?.structure?.cracks}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Structural Bolts:</label><span className="fw-400">  {item?.structure?.structureBolts}</span></div>
              </div>
              <div className="col-md-6">
                <div className="mb-2"> <label className="font-bold-custom">Pitman Arms:</label><span className="fw-400">  {item?.structure?.pitmanArms}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Left Side:</label><span className="fw-400">  {item?.structure?.leftSide}</span></div>
                <div className="mb-2"> <label className="font-bold-custom">Right Side:</label><span className="fw-400">  {item?.structure?.rightSide}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-deck">
          <div className="p-4 col">
            <div className="row mb-4">
              <div className="col-md-12 bg-info p-2">
                <h3 className="center">Comments</h3>
              </div>
            </div>
            <div className="px-4 fs-18 fw-400 row">{item?.technicianComments}</div>
          </div>
        </div>
        <div className="card-deck">
          <div className="p-4 col">
            <div className="row mb-4">
              <div className="col-md-12 bg-info p-2">
                <h3 className="center">Photos</h3>
              </div>

              <div className='d-flex flex-wrap justify-content-center'>
                {item?.photos?.length > 0 &&
                  item?.photos.map((item, index) => (
                    <Image key={index} src={BASE_IMG_URL + item} thumbnail />
                  ))}
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceDetail