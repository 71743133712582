import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { UserAvatar } from '../components/global/UserAvatar';
import { apiPost } from '../util/ApiRequest';
import { ENDPOINTS } from '../util/EndPoint';
import { isLoaderState } from "../util/RecoilStore";


const CompanyUsers = () => {

  const { id } = useParams();

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [items, setItems] = useState([]);
  const [currentUrl, setCurrentUrls] = useState('');



  useEffect(() => {
    const url = window.location.href;
    const lastSegment = url.substring(url.lastIndexOf("/") + 1);
    setCurrentUrls(lastSegment)
    if (lastSegment) {
      getCompanyUsers(lastSegment);
    }
  }, []);







  const getCompanyUsers = (paramId) => {
    setIsLoaderInfo(true);
    apiPost(
      ENDPOINTS.UserCompany,
      { companyId: paramId },
      (res) => {
        setItems(res?.data);
        setIsLoaderInfo(false);
      },
      (error) => {
        console.log(error);
        setIsLoaderInfo(false);
      }
    );
  };


  return (
    <>

      <div className='container card '>
        <div className='row'>
          <div className='col-12'>
            <h2 className='mb-4'>Company users</h2>
          </div>
          {items?.length > 0 &&
            items.map((item, index) => (
              <div key={index} className='col-lg-6 col-md-12 mb-2'>
                <UserAvatar user={item} />
              </div>
            ))}
        </div>

      </div>


    </>
  )
}

export default CompanyUsers