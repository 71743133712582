import React, { useEffect, useState } from 'react';

const CustomCheckBox = ({ name, label, defaultValue }) => {

  const [isCheck, setIsCheck] = useState(false);
  useEffect(() => {
    setIsCheck(defaultValue)
  }, [defaultValue]);

  return (
    <div className="col-md-6">
      <div className="form-check form-check-inline">
        <label className="form-check-label">
          <input
            type="checkbox"
            className="form-check-input"
            checked={isCheck ? true : false}
            onChange={() => setIsCheck(!isCheck)}
          />
          {label}
        </label>

        <input type='hidden' value={isCheck ? 1 : 0} name={name} />
      </div>
    </div>
  )
}

CustomCheckBox.defaultProps = {
  defaultValue: false
};
export default CustomCheckBox