import { useRef, useState } from "react";
import {
    ReactSketchCanvas
} from "react-sketch-canvas";

const SignatureComponent = ({ handleSignData, index }) => {
    const canvasRef = useRef(null);
    const [eraseMode, setEraseMode] = useState(false);

    const handlePenClick = () => {
        setEraseMode(false);
        canvasRef.current?.eraseMode(false);
    };

    const handleClearClick = () => {
        canvasRef.current?.clearCanvas();
    };

    const handleExportImage = async () => {
        if (canvasRef.current) {
            const data = await canvasRef.current?.exportImage('png');
            handleSignData(data, index)
        }
    };


    return (
        <div className="d-flex flex-column gap-2 p-2 ">
            <div className="d-flex gap-2 align-items-center ">
                <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    disabled={!eraseMode}
                    onClick={handlePenClick}
                >Pen
                </button>
                <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    onClick={handleClearClick}
                >Clear
                </button>

            </div>
            <ReactSketchCanvas
                onChange={handleExportImage}
                ref={canvasRef} />
        </div>
    );
}

export default SignatureComponent