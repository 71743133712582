import React from 'react';
import Form from 'react-bootstrap/Form';


const YesNoSelect = ({ label, name, setValue, defaultValue }) => {
    return (
        <>
            <label className="mb-2">{label}</label>
            <Form.Select onChange={(e) => setValue(e.target.value)} name={name} defaultValue={defaultValue}>
                <option value="yes">Yes</option>
                <option value="no">No</option>
            </Form.Select>
        </>
    )
}

export default YesNoSelect